import Header from "../../../Components/Header/Header";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {showAlert} from "../../../Services/CommonFunctions";
import {updateUserInfoContryCd} from "../../../Services/session.mgmt";

function UserVerification(){

    const navigate = useNavigate();
    const [country, setCountry] = useState("0");
    function handleOnclick(){
        if(country === "0"){
            showAlert("Please select a country.", "warning").then(r =>console.log(r)) ;
        }
        else{
            updateUserInfoContryCd(`${country}`);
            navigate(`/contractorprofile`)
        }
    }
    useEffect(() => {

    }, []);
    return(

        <div style={{alignItems: "center", marginLeft: "20%", marginRight: "20%", marginTop: "2%"}}>
            <Header heading="Repo Admin Tool"/>
            <br/>
            <div>
                <div className="fds-layout-grid">
                    <div className="fds-layout-grid__inner">
                        <div className="fds-layout-grid__cell--span-10">
                            <div>
                                <p>Please select the country you would like to work.</p>
                                <label>Please Select Country:</label>
                                <select name="selectCountry"  className="s-1"
                                        style={{marginLeft: '2px'}}
                                        onChange={(e)=>setCountry(e.target.value)}>
                                    <option value= "0" >None</option>
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                </select>
                            </div>
                            <button className="fmc-button" onClick={handleOnclick}>OK</button>
                        </div>
                        <div className="fds-layout-grid__cell--span-2">
                            <div>
                                <Link to={`/misc`}>Repoweb</Link><br/>
                                <Link id='mockuserinfolnk' style={{display: 'none'}} to={`/mockUserInfo`}>mockUserInfo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserVerification;