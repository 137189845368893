export  const  PINNCALE_LOG_OBJ={

    "systemName": "WORKFLOW",
    "enteredBranchCode": "",
    "customerServiceFollowUpDate": "",
    "customerServiceFollowUpTime": "",
    "comment": "",
    "isPermanent": true,
    "thirdPartySequenceNumber": null,
    "contactThirdPartySequenceNumber": null,
    "createdBy": "",
    "createdTimeStamp": "2024-06-14 17:20:29",
    "typeCode": 4,
    "actionCode": 137,
    "partyCode": 6,
    "resultsCode": 466,
    "reasonCode": 370,
    "rootCause": 78,
    "categoryCode": 52,
    "receivableAccountNumber": 58772772,
    "coBusCd": 1,
    "promiseToPayDate1": null,
    "promiseToPayAmount1": null,
    "promiseToPayDate2": null,
    "promiseToPayAmount2": null
}


export  const VEHICLE_DISPOSAL_OBJ =
    {
        custAcctNb: 356744684,
        coBusCd: 7201,
        discorigBranch: "AC",
        teamworkBPI: 1479,
        vin: "1FA6P0H76E5378637",
        repoTrmDt: "2024-09-03T14:08:26.404Z", // vcr
        mlDrvnNb: 0, //vcr
        dispoRsnCd: 1, // constant
        crtDt: "2024-09-03T14:08:26.404Z", //curTime
        legalSaleDt: "2024-09-03T14:08:26.404Z",
        lgclDelIn: null, // sending as null
        availPkDt: "2024-09-03T14:08:26.404Z",  //repoTrmDt
        lastUpdatedUserIdentificationCode: "dravich8",
        lastUpdatedTimestamp: "2024-09-03T14:08:26.404Z"
    }



export const AssignmentSummaryObj =
    {
        buyerfirstname: "MECHAKAA",
        buyermiddleinitial: "J",
        buyerlastname:"erer",
        custacctno:"23456",
        dor:"01-11-2024",
        vehyear:"2015",
        vehmake:"2011",
        vehmodel:"klhl",
        vin:"1234456fdgdfh4g",
        preparedby:"bjdbfjzdb",
        preparedate:"01-11-2024",
        cntrBusNm:"kjgug",
        addrlin1txt:"ankf",
        cityNm:"dsfd",
        stateabbrCode:"gsgfd",
        zipcode:"12345",
        odometer:"2334",
        ignitionkey:"5656",
        vehcolor:"green",
        gencond:"Good",
        mechanical:"Good",
        glass_desc:"Good",
        tire_lf:"Good",
        tire_rf:"Good",
        tire_rr:"Good",
        tire_lr:"Good",
        tire_sp:"Good",
        interior_desc:"Good",
        body_body:"Good",
        body_paint:"Good",
        body_bumpers:"Good",
        drds_fqp:"Good",
        drds_fd:"Good",
        drds_rd:"Good",
        drds_rqp:"Good",
        drps_fqp:"Good",
        drps_fd:"Good",
        drps_rd:"Good",
        drps_rqp:"Good",
        drt_h:"Good",
        drt_r:"Good",
        drt_t:"Good",
        drb_f:"Good",
        drb_r:"Good",
        rme:"Good",
        est_damage_amount:"Good",
        vrf_desc:"Good",
        recfrom_addr:"Good",
        recfrom_city:"Good",
        recfrom_state:"Good",
        recfrom_zip:"Good",
        release_signed_desc:"Good",
        authorities:"Good",
        authorities_policedeptname:"Good",
        authorities_addr:"Good",
        authorities_city:"Good",
        authorities_state:"Good",
        authorities_zip:"Good",
        vcr_comments:"Good",
        vehloc:"Good",
        locName:"Good",
        isNotAuction:"false",
        vehlocnameis:"Good",
        vehlocaddr1:"Good",
        vehloccity:"Good",
        vehlocstate:"Good",
        vehloczip:"123456",
        vehlocphonearea:"123",
        vehlocphone:"453646",

        amt1:"Amount 1",
        desc1:"Description 1",
        line01code:"All Inclusive Repo Fee On File",
        line01amount:"$100.00",
        amt2:"Amount 2",
        desc2:"Description 2",
        line02code:"Storage(Storage paid receipt required)",
        line02amount:"$100.00",
        amt3:"Amount 3",
        desc3:"Description 3",
        line03code:"All Inclusive Repo Fee On File",
        line03amount:"$100.00",
        amt4:"Amount 4",
        desc4:"Description 4",
        line04code:"All Inclusive Repo Fee On File",
        line04amount:"$100.00",
        amt5:"Amount 5",
        desc5:"Description 5",
        line05code:"All Inclusive Repo Fee On File",
        line05amount:"$100.00",
        amt6:"Amount 6",
        desc6:"Description 6",
        line06code:"All Inclusive Repo Fee On File",
        line06amount:"$100.00",
        amt7:"Amount 7",
        desc7:"Description 7",
        line07code:"",
        line07amount:"$100.00",
        recovered:"No",
        propReturned:"N",
        propDisposed:"Y",
        item01:"Pen1",
        item02:"Pen2",
        item03:"book1",
        item04:"book2",
        item05:"book",
        item06:"book",
        item07:"book",
        item08:"book",
        item09:"book",
        item10:"book",
        item11:"book",
        item12:"book12",
        item13:"book13",
        item14:"book14",
        item15:"book15",
        storagelocation:"abccc"
    }