import React, { useState, useEffect } from 'react';
import { setUserInfoToSession, getUserInfoFromSession } from '../Services/session.mgmt';

const MockUserInfo = () => {
  const [userInfo, setUserInfo] = useState(getUserInfoFromSession() || {
    userid: '',
    countryCd: '',
    sitecode: '',
    isExternal: false,
    isAdmin: false,
    hasContractProfile: false,
    cntrctrNb: ''
  });

  useEffect(() => {
    const storedUserInfo = getUserInfoFromSession();
    if (storedUserInfo) {
      setUserInfo(storedUserInfo);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserInfoToSession(userInfo);
  };

  return (
    <section className="form-demo">
      <form onSubmit={handleSubmit} className="faq-search fmc-mb-4">
        <div>
          <label>User ID:</label>
          <input type="text" name="userid" value={userInfo.userid} onChange={handleChange} />
        </div>
        <div>
          <label>Country Code:</label>
          <input type="text" name="countryCd" value={userInfo.countryCd} onChange={handleChange} />
        </div>
        <div>
          <label>Site Code:</label>
          <input type="text" name="sitecode" value={userInfo.sitecode} onChange={handleChange} />
        </div>
        <div>
          <label>Is External:</label>
          <input type="checkbox" name="isExternal" checked={userInfo.isExternal} onChange={handleChange} />
        </div>
        <div>
          <label>Is Admin:</label>
          <input type="checkbox" name="isAdmin" checked={userInfo.isAdmin} onChange={handleChange} />
        </div>
        <div>
          <label>Has Contract Profile:</label>
          <input type="checkbox" name="hasContractProfile" checked={userInfo.hasContractProfile} onChange={handleChange} />
        </div>
        <div>
          <label>Contractor Number:</label>
          <input type="text" name="cntrctrNb" value={userInfo.cntrctrNb} onChange={handleChange} />
        </div>
        <fmc-button type="submit" label="Update" onClick={handleSubmit}/>
      </form>
    </section>
  );
};

export default MockUserInfo;