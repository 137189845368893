import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { profileService } from '../../Services/ProfileService';
import Header from '../../Components/Header/Header';
import DatePicker from 'react-datepicker';
import Address from '../../Components/Address';
import _ from 'lodash';
import PriceStruct from '../../Components/PriceStruct';
import Contact from '../../Components/Contact';
import TextAreaCharCount from '../../Components/TextAreaCharCount';
import Footer from '../../Components/Footer/Footer';
import { getUserInfoFromSession } from '../../Services/session.mgmt';

const ProfileUpdate = () => {
  const [contractorDetail, setContractorDetail] = useState({});
  const [contractorProfile, setContractorProfile] = useState(null);
  const [personalDetail, setPersonalDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [closeErrorDiaglog, setCloseErrorDiaglog] = useState(true);
  const [errors, setErrors] = useState({});
  const [localPriceStructs, setLocalPriceStructs] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [buzCenters, setBuzCenters] = useState([]);
  const { contractorNb } = useParams();
  const [contractorLoginId, setContractorLoginId] = useState(null);
  const [originalContractorLoginId, setOriginalContractorLoginId] = useState(null);
  const userInfo = getUserInfoFromSession();
  const nav = useNavigate();

  useEffect(() => {
    const fetchContractorProfile = async () => {
      try {
        const buzCenterResponse = await profileService.getBuzCenters();
        setBuzCenters(buzCenterResponse.data);
        const contractorIdDetails = await profileService.getContractorIdDetails(contractorNb);
        setContractorLoginId(contractorIdDetails?.data?.result?.trim());
        setOriginalContractorLoginId(contractorIdDetails?.data?.result?.trim());

        const contractProfileResp = await profileService.getContractorProfile(contractorIdDetails?.data?.result?.trim());
        const contractProfileRespData = contractProfileResp.data[0];
        setContractorProfile(contractProfileRespData);
        console.log('=========== contractorProfile =========' + JSON.stringify(contractorProfile));
       

        const contractPersonalResp = await profileService.getContractorPersonal(contractorNb);
        const personalDetailsRespData = {
          ...contractPersonalResp.data.result.cntrctrData2ApiList[0],
          cntrctrNb: contractorNb,
          cntrctrTypeCd: contractProfileRespData.cntrctrTypeCd,
          lstUpdtUserIdCd: userInfo.userid,
        };
        setPersonalDetail(personalDetailsRespData);
        console.log('======== personalDetails ==============' + JSON.stringify(personalDetail));
        //get price structor data
        const feePriceStructResp = await profileService.getContractorPriceStructs(contractorNb);
        _.forEach(feePriceStructResp.data.result.cntrctrFeeVector, (value) => {
          value.status = 'exist';
        });
        setLocalPriceStructs(feePriceStructResp.data.result.cntrctrFeeVector);
      } catch (err) {
        console.error('Failed to fetch contractorData' + err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchContractorProfile();
  }, [contractorNb, userInfo.userid]);

  function getContractorTypeCdByUserId(result) {
    profileService.getContractorProfile(result)
      .then((contractProfileResp) => {
        if(contractProfileResp.data && contractProfileResp.data.length > 0) {
          setPersonalDetail((prevCntrctrPersonal) => ({
            ...prevCntrctrPersonal,
            cntrctrTypeCd: contractProfileResp.data[0].cntrctrTypeCd,
          }));
        }
      });
  }

  const validateInputs = () => {
    if (!personalDetail.cntrctrBusNm) {
      errors.cntrctrBusNm = 'Contractor name cannot be blank.';
    } else {
      delete errors.cntrctrBusNm;
    }
    if (!personalDetail.effDt) {
      errors.effDt = 'Effective Date cannot be blank.';
    } else {
      delete errors.effDt;
    }
    if (!personalDetail.expirDt) {
      errors.expirDt = 'Expiration Date is required';
    } else {
      delete errors.expirDt;
    }
    if (!personalDetail.taxNb) {
      errors.taxNb = 'Federal Tax Id is required';
    } else {
      delete errors.taxNb;
    }
    if(!contractorLoginId){
      errors.cntrctrUserIdCd = 'Contractor login id must not in blank.';
    } else {
      delete errors.cntrctrUserIdCd;
    }
    if (Object.keys(errors).length > 0) {
      setCloseErrorDiaglog(false);
    }
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    validateInputs();
    if (Object.keys(errors).length === 0) {
      try {
        checkAndUpdateUserId();
      } catch (e) {
        console.log('update personalDetail failed: ' + JSON.stringify(e));
      }
    } else {
      setCloseErrorDiaglog(false);
    }
  };

  const checkAndUpdateUserId = () => {
    if (originalContractorLoginId !== contractorLoginId) {
      profileService.isContractorAvailable(contractorLoginId).then((value) => {
        // 200 means contractor id exists. 204 means it does not exist
        if (value.status === 204) {
          saveContractorIdDetails();
        } else {
          setErrors((prevState) => {
            return { ...prevState, userId: 'Contractor login id is not available.' };
          });
          setCloseErrorDiaglog(false);
        }
      });
    } else {
      updateProfilePromise();
    }
  };

  const saveContractorIdDetails = () => {
    profileService
      .saveContractorIdDetails({
        cntrctrUserIdCd: contractorLoginId,
        cntrctrNb: Number(personalDetail.cntrctrNb),
        lstUpdtUserId: userInfo['userid'],
      })
      .then(() => {
        updateProfilePromise();
      })
      .catch((e) => {
        setErrors((prevState) => {
          return { ...prevState, cntrctrUserIdCd: 'Contractor login id is not available.' };
        });
        setCloseErrorDiaglog(false);
      });
  };

  const updateProfilePromise = () => {
    Promise.all([
      profileService.updatePersonalDetail(personalDetail),
      profileService.updatePriceStruct(
        localPriceStructs.map((ps) => {
          return {
            prcStrctNb: ps.prcStrctNb,
            prcStrctTx: ps.prcStrctTx,
            feeAm: Number(ps.feeAm),
            vlntrFeeAm: Number(ps.vlntrFeeAm),
            vlntrTodlrFeeAm: Number(ps.vlntrTodlrFeeAm),
            cntngntIn: ps.cntngntIn,
            dlvryToAuctIn: ps.dlvryToAuctIn,
            gvwTypeCd: ps.gvwTypeCd,
            clsFeeAm: Number(ps.clsFeeAm),
            effDtStr: ps.effDt,
            lstUpdtUserId: userInfo.userid,
            status: ps.status,
          };
        }),
        personalDetail.cntrctrNb,
        personalDetail.cntryCd
      ),
      profileService.updateContacts(contacts, personalDetail.cntrctrNb, userInfo.userid),
    ]).then((results) => {
      setUpdateSuccess(true);
    });
  };

  const handleCancelProfile = () => {
    nav('/contractorprofile');
  };
  const updateContractAddress = (fieldName, fieldValue, addressErrors) => {
    if (Object.keys(addressErrors).length > 0) {
      setErrors((prevState) => {
        return { ...prevState, ...addressErrors };
      });
    } else {
      setErrors((prevState) => {
        delete prevState[fieldName];
        return prevState;
      });
    }
    setPersonalDetail((prevCntrctrPersonal) => ({
      ...prevCntrctrPersonal,
      [fieldName]: fieldValue,
    }));
  };
  const handleAddPriceStruct = () => {
    let idMax = localPriceStructs.length;
    //get
    idMax = _.maxBy(localPriceStructs, 'prcStrctNb')?.prcStrctNb + 1;
    const newPriceStruct = {
      cntngntIn: 'C',
      dlvryToAuctIn: 'D',
      gvwTypeCd: 'M',
      prcStrctNb: idMax,
      status: 'new',
    };
    setLocalPriceStructs((prevPriceStructs) => [...prevPriceStructs, newPriceStruct]);
  };
  const handleTextCommentsUpdate = (textArea) => {
    switch (textArea.fieldName) {
      case 'busLcnsTx':
        setPersonalDetail((prevPersonalDetail) => ({
          ...prevPersonalDetail,
          busLcnsTx: textArea.text,
        }));
        console.log('update textArea from busLcnsTx: ' + JSON.stringify(personalDetail));
        break;
      case 'insInfoTx':
        setPersonalDetail((prevPersonalDetail) => ({
          ...prevPersonalDetail,
          insInfoTx: textArea.text,
        }));
        console.log('update textArea from insInfoTx: ' + JSON.stringify(personalDetail));
        break;
      case 'othrInfoTx':
        setPersonalDetail((prevPersonalDetail) => ({
          ...prevPersonalDetail,
          othrInfoTx: textArea.text,
        }));
        console.log('update textArea from othrInfoTx: ' + JSON.stringify(personalDetail));
        break;
      default:
        break;
    }
  };
  const handleBuzCenterSelected = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      mngtBcLoc: value,
    }));
  };
  const handlePersonalDetailGsdbCd = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      gsdbCd: value,
    }));
  };
  const formatDate = (date) => {
    // Extract month, day, and year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
  
    // Return formatted string
    return `${month}/${day}/${year}`;
  }
  const handlePersonalDetailEffDt = (date) => {
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      effDt: formatDate(date),
    }));
  };
  const handlePersonalDetailExpirDt = (date) => {
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      expirDt: formatDate(date),
    }));
  };
  const handlePersonalDetailTaxNb = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevPersonalDetail) => ({
      ...prevPersonalDetail,
      taxNb: value,
    }));
  };

  const handleCompanyName = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevCntrctrPersonal) => ({
      ...prevCntrctrPersonal,
      cntrctrBusNm: value,
    }));
  };
  const handleUserId = (e) => {
    const { value } = e.target;
    setContractorLoginId(value);
  };
  const handleRdnInd = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevCntrctrPersonal) => ({
      ...prevCntrctrPersonal,
      rdnInd: new Number(value),
    }));
  };
  const handleContractorTypeCd = (e) => {
    const { value } = e.target;
    setPersonalDetail((prevCntrctrPersonal) => ({
      ...prevCntrctrPersonal,
      cntrctrTypeCd: value,
    }));
  };
  const handleContactUpdates = (pDetail, errors) => {
    //update Contact
    setPersonalDetail((prevPersonalDetails) => ({ ...prevPersonalDetails, ...pDetail }));
  };
  const handleContactPhoneUpdates = (cPhoneList) => {
    setContacts(cPhoneList);
  };
  const handlePriceStructUpdate = (psIdIn, fieldName, fieldValue, psError) => {
    if (psError && Object.keys(psError).length > 0) {
      setErrors((prevState) => {
        return { ...prevState, ...psError };
      });
    } else {
      setErrors((prevState) => {
        delete prevState[fieldName + '-' + psIdIn];
        return prevState;
      });
    }
    _.forEach(localPriceStructs, (elm) => {
      if (psIdIn === elm.prcStrctNb) {
        elm[fieldName] = fieldValue;
      }
    });
  };
  const handleCloseErrorDiaglog = () => {
    setCloseErrorDiaglog(true);
    setErrors({});
  };
  const handleBackOrCancel = () => {
    nav('/contractorprofile');
  };
  if (loading)
    return (
      <>
        <Header heading="Modify Contractor Profile" summaryDisplay="none" profileDisplay="none" />
        <br />
        Loading...
      </>
    );
  if (!personalDetail)
    return (
      <>
        <Header heading="Modify Contractor Profile" summaryDisplay="none" profileDisplay="none" />
        <br />
        No profile found
      </>
    );
  if (Object.keys(errors).length > 0 && !closeErrorDiaglog)
    return (
      <>
        <div
          className="fmc-dialog fmc-dialog--opened fds-align--center"
          role="dialog"
          aria-modal="true"
          aria-label="Lorem Ipsum Dolor"
        >
          <div className="fmc-dialog__content">
            <button
              className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
              aria-label="A close button."
              onClick={handleCloseErrorDiaglog}
            ></button>
            <h3 className="fmc-type--heading5 fds-m--b-2">Errors</h3>
            <h4 className="fmc-type--subtitle1 fds-m--b-2">Error Details</h4>
            <div style={{ textAlign: 'left' }}>
              {_.map(errors, (error, key) => (
                <p key={key} style={{ color: 'red' }} className="fmc-type--body1 fds-m--b-2">
                  {error}
                </p>
              ))}
            </div>
            <div className="fds-m--t-3">
              <button className="fmc-button fmc-button--outlined fds-m--b-2" onClick={handleCloseErrorDiaglog}>
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <Header heading="Modify Contractor Profile" summaryDisplay="none" profileDisplay="none" />
      {updateSuccess ? (
          <div className="fmc-alert fmc-alert--success fmc-mb-3" role="alert">
            <p>Contractor information updated successfully</p>
            <fmc-button name="Back" label="Back" onClick={handleBackOrCancel}/>
          </div>
      ) : (
        <div className="margin10">
          <table border="5" style={{ width: '100%' }} className="fmc-table">
            <thead>
              <tr>
                <th width="40%"></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label for="cntrctrBusNm">Company name:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="cntrctrBusNm"
                    name="cntrctrBusNm"
                    className="fmc-input"
                    value={personalDetail.cntrctrBusNm}
                    maxLength="30"
                    onChange={handleCompanyName}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Contractor number:</label>
                </td>
                <td>{personalDetail.cntrctrNb}</td>
              </tr>
              <tr>
                <td>
                  <label>Contractor login id:</label>
                </td>
                <td>
                  <input
                    type="text"
                    name="cntrctrUserIdCd"
                    data-testid="cntrctrUserIdCd"
                    value={contractorLoginId}
                    className="fmc-input"
                    onChange={handleUserId}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>RDN Indicator:</label>
                </td>
                <td>
                  <select
                    className="fmc-select"
                    id="rdnInd"
                    name="rdnInd"
                    value={personalDetail.rdnInd}
                    onChange={handleRdnInd}
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>GSDB Code:</label>
                </td>
                <td>
                  <input
                    id="gsdbCd"
                    name="gsdbCd"
                    type="text"
                    value={personalDetail.gsdbCd}
                    className="fmc-input"
                    onChange={handlePersonalDetailGsdbCd}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Effective Date:</label>
                </td>
                <td>
                  <div style={{ minWidth: '12em' }}>
                    <DatePicker
                        className="fmc-input"
                        dateFormat="MM/dd/YYYY"
                        selected={personalDetail.effDt}
                        id="effDt"
                        name="effDt"
                        customInput={<input data-testid="effDtTestId"/>}
                        onChange={handlePersonalDetailEffDt}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Expiration Date:</label>
                </td>
                <td>
                  <div style={{ minWidth: '12em' }}>
                    <DatePicker
                      className="fmc-input"
                      dateFormat="MM/dd/YYYY"
                      selected={personalDetail.expirDt}
                      id="expirDt"
                      name="expirDt"
                      onChange={handlePersonalDetailExpirDt}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Contractor Type Code:</label>
                </td>
                <td>
                  <select
                    id="cntrctrTypeCd"
                    name="cntrctrTypeCd"
                    value={personalDetail.cntrctrTypeCd}
                    className="fmc-select"
                    onChange={handleContractorTypeCd}
                  >
                    <option value="7">Repossession</option>
                    <option value="9">CSR-Repo</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Managing Business Center Location:</label>
                </td>
                <td>
                  <select
                    id="superBranchCd"
                    name="superBranchCd"
                    value={personalDetail.mngtBcLoc}
                    className="fmc-select"
                    onChange={handleBuzCenterSelected}
                  >
                    {buzCenters.map((buz) => (
                      <option value={buz.superBranchCd} key={buz.superBranchCd}>
                        {buz.superBranchCd} - {buz.branchNm}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <Address
                addressData={{
                  addressLine: personalDetail.addrLine1Tx,
                  stateOrProvCodes: personalDetail.stAbbrCd,
                  stateOrZip: personalDetail.zipCd,
                  city: personalDetail.cityNm,
                  email: personalDetail.emailAddrTx,
                  countryCd: userInfo.countryCd,
                }}
                onChange={updateContractAddress}
              ></Address>
              <tr>
                <td>
                  <label>Federal Tax Id:</label>
                </td>
                <td>
                  <input
                    id="taxNb"
                    name="taxNb"
                    type="text"
                    className="fmc-input"
                    value={personalDetail.taxNb}
                    onChange={handlePersonalDetailTaxNb}
                  />
                </td>
              </tr>
              {localPriceStructs ? (
                _.map(localPriceStructs, (ps) => (
                  <PriceStruct
                    key={ps.prcStrctNb}
                    prcStrctData={{
                      psId: ps.prcStrctNb,
                      name: ps.prcStrctTx,
                      selfHelpAmt: ps.feeAm,
                      volFeeAmt: ps.vlntrFeeAm,
                      dealerVolFeeAmt: ps.vlntrTodlrFeeAm,
                      closeFeeAmt: ps.clsFeeAm,
                      cntngntIn: ps.cntngntIn,
                      dlvryToAuctIn: ps.dlvryToAuctIn,
                      gvwTypeCd: ps.gvwTypeCd,
                      status: ps.status,
                    }}
                    onUpdate={handlePriceStructUpdate}
                  />
                ))
              ) : (
                <PriceStruct prcStrctData={{ prcStrctNb: 0, status: 'new' }} onUpdate={handlePriceStructUpdate} />
              )}
              <tr>
                <td>
                  <fmc-button data-testid="addPriceStructButton"
                    name="Add a New Price Structure"
                    label="Add a New Price Structure"
                    onClick={handleAddPriceStruct}
                  />
                </td>
                <td></td>
              </tr>
              {personalDetail ? (
                <Contact
                  psnlDetail={personalDetail}
                  errors={errors}
                  onContactChange={handleContactUpdates}
                  onPhoneChange={handleContactPhoneUpdates}
                />
              ) : (
                <p>Loading...</p>
              )}
              <tr>
                <td>
                  <label>Bus Lcns Text:</label>
                </td>
                <td>
                  {personalDetail ? (
                    <TextAreaCharCount
                      formData={{
                        fieldName: 'busLcnsTx',
                        text: personalDetail.busLcnsTx,
                      }}
                      maxChar={120}
                      onUpdate={handleTextCommentsUpdate}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>Ins Info Text:</label>
                </td>
                <td>
                  {personalDetail ? (
                    <TextAreaCharCount
                      formData={{
                        fieldName: 'insInfoTx',
                        text: personalDetail.insInfoTx,
                      }}
                      maxChar={120}
                      onUpdate={handleTextCommentsUpdate}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <label>Other:</label>
                </td>
                <td>
                  {personalDetail ? (
                    <TextAreaCharCount
                      formData={{
                        fieldName: 'othrInfoTx',
                        text: personalDetail.othrInfoTx,
                      }}
                      maxChar={255}
                      onUpdate={handleTextCommentsUpdate}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="centerAlign" style={{ paddingTop: '2em' }}>
            <fmc-button name="Update" data-testid="updateFMCButton" label="Update" onClick={handleUpdateProfile} />
            &nbsp;&nbsp;
            <fmc-button name="Cancel" data-testid="cancelFMCButton" label="Cancel" onClick={handleCancelProfile} />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};
export default ProfileUpdate;
