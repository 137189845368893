import {
    createCounty,
    createPostalCode,
    deleteCACoverage,
    deleteUSCoverage,
    getCountiesByStAbbrCd,
    getCountyData,
    getPostalCodeByStAbbrCd,
    getPostalCodeData,
    getPriceStructByContractorNb,
    getProvinces,
    getSelectedCountiesByStAbbrCd,
    getSelectedPostalCodeByStAbbrCd,
    getStates
} from "../api/coverage.api";
import _ from "lodash";

export const coverageService = {
    getCoverageByContractorNb: async (contractNb, countryCd) => {
        let viewCoverages = [];
        if(countryCd === 'CAN'){
            await getPostalCodeData(contractNb).then(res => {
                viewCoverages = res.data.map((cv) => {
                    return {
                        prcStrctTx: cv.prcStrctTx,
                        stateOrProv: cv.stAbbrCd,
                        countyOrPC: cv.zipCd
                    }
                });
            });
        }else{
            await getCountyData(contractNb).then(res => {
                console.log("data");
                viewCoverages = res.data.map((cv) => {
                    return {
                        prcStrctTx: cv.prcStrctTx,
                        stateOrProv: cv.stAbbrCd,
                        countyOrPC: cv.cntyNm
                    }
                });
            });
        }
        return viewCoverages;
    },
    getPriceStructByContractorNb: async (contractNb) => {
        return getPriceStructByContractorNb(contractNb);
    },
    getStateOrProvinceByStAbbr: async (countryCd) => {
        let stateOrProvince = [];
        if(countryCd === 'CAN'){
            await getProvinces().then(res => {
                stateOrProvince = res.data.map((prv)=>{
                    return {
                        stAbbrCd: prv.provinceAbbrCode
                    };
                });
            });
        }else {
            await getStates().then(res => {
                stateOrProvince = res.data.map((prv)=>{
                    return {
                        stAbbrCd: prv.stateabbrCode
                    };
                });
            });
        }
        _.sortBy(stateOrProvince, ['stAbbrCd']);
        return Promise.resolve(stateOrProvince);
    },
    getCountyOrPCByStAbbr: async (stateOrProvCd, countryCd) => {
        let countyOrPCs = [];
        if(countryCd === 'CAN'){
            await getPostalCodeByStAbbrCd(stateOrProvCd).then(res => {
                countyOrPCs = res.data.map((pc)=>{
                    return {
                        value: pc.zipPrefxCd,
                        label: pc.zipPrefxCd
                    };
                });
            });
        }else {
            await getCountiesByStAbbrCd(stateOrProvCd).then(res => {
                countyOrPCs = res.data.map((county)=>{
                    return {
                        value: county.cntyCd,
                        label: county.cntyNm
                    };
                });
            });
        }
        _.sortBy(countyOrPCs, ['stAbbrCd']);
        return Promise.resolve(countyOrPCs);
    },
    getSelectedCountyOrPCs: async (countryCd, contractNb, stAbbrCd) => {
        let selectedCountyOrPCs = [];
        if(countryCd === 'CAN'){
            await getSelectedPostalCodeByStAbbrCd(contractNb, stAbbrCd).then(res => {
                if(res.data) {
                    selectedCountyOrPCs = res.data.map((cvg) => {
                        return {
                            countyOrPc: cvg.zipPrefxCd,
                            prcStrctNb: cvg.prcStrctNb
                        };
                    });
                }
            });
        }else {
            await getSelectedCountiesByStAbbrCd(contractNb, stAbbrCd).then(res => {
                if(res.data) {
                    selectedCountyOrPCs = res.data.map((cvg) => {
                        return {
                            countyOrPc: cvg.cntyCd,
                            prcStrctNb: cvg.prcStrctNb
                        };
                    });
                }
            });
        }
        return Promise.resolve(selectedCountyOrPCs);
    },
    updateCountyOrPC: async (countryCd, contractNb, stateOrProv, priceStruct, countyOrPcs, userId) => {
        console.log("updateCountyOrPC: ["+countryCd+"-"+contractNb+"-"+stateOrProv+"-"+priceStruct+"]");
        if(countryCd === 'CAN'){
            //delete all first
            const deleteRecordsResp = await deleteCACoverage(contractNb, stateOrProv, priceStruct);
            if (deleteRecordsResp.status === 200) {
                const updatedCountyOrPC = _.map(countyOrPcs, async (countOrPc) => {
                    const createdCountRes = await createPostalCode(parseInt(contractNb), countOrPc, stateOrProv, userId, parseInt(priceStruct));
                    return createdCountRes.data;
                });
                return await Promise.all(updatedCountyOrPC);
            }
        }else{
            //delete all first
            const deleteRecordsResp = await deleteUSCoverage(contractNb, stateOrProv, priceStruct);
            if(deleteRecordsResp.status === 200) {
                const updatedCountyOrPC = _.map(countyOrPcs, async (countOrPc) => {
                    const createdCountRes = await createCounty(parseInt(contractNb), parseInt(countOrPc), stateOrProv, userId, parseInt(priceStruct));
                    return createdCountRes.data;
                });
                return await Promise.all(updatedCountyOrPC);
            }
        }
    }
}