import React, { useEffect, useState } from 'react';
import './Summary.css';
import { getPPropertyInfoFromSession } from "../../Services/session.mgmt";
import { AssignmentService } from '../../Services/AssignmentService';

function Invoicesummary({teamWrkBpiNb}) {

  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.all([
          AssignmentService.getInvoiceDetails(teamWrkBpiNb),
          AssignmentService.getInvoiceTypes(1),
        ]).then(([InvoiceResponse, InvoiceTypesResponse]) => {
          setInvoiceData(InvoiceResponse.data);
          const invoiceTypeRespMapped = Object.keys(InvoiceTypesResponse.data).map(key => ({
            key: Number(key),
            value: InvoiceTypesResponse.data[key]
          }));
          setInvoiceTypes(invoiceTypeRespMapped);//Map invoice data to invoice types
          if (InvoiceResponse.data.length && invoiceTypeRespMapped.length) {
            let totalAmount = 0;
            const matched = InvoiceResponse.data.map(invoice => {
              const matchingItem = invoiceTypeRespMapped.find(item => item.key.toString() === invoice.cmpssEntryCd);
              totalAmount = totalAmount + invoice.cntrctrChgAm
              return matchingItem
                ? {
                  cmpssEntryCd: invoice.cmpssEntryCd,
                  cntrctrChgAm: invoice.cntrctrChgAm,
                  cmpssEntryDs: matchingItem.value
                }
                : null;
            }).filter(Boolean);
            setInvoiceData(matched);
            setTotal(totalAmount);
            console.log(totalAmount)
          }
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          setLoading(false);
        });
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    // Data is not available yet
    return <div>Loading...</div>; // Or handle as needed
  }

  return (
      <div id='details_tab_cntr'>
        <div className='details_cntr'>
          <div id='invoiceheader' className='center'>
            INVOICE
          </div>

          <div className='invoicepart'>
            <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
              <div className='center'>
                <table className="fmc-table">
                  <thead>
                  <tr>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {Array.isArray(invoiceData) && invoiceData.map((invoice, index) => (
                      <tr key={index}>
                        <td>{invoice.cmpssEntryDs ?? "N/A"}</td>
                        <td>${invoice.cntrctrChgAm ?? "0.00"}</td>
                      </tr>
                  ))}
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Total</td>
                    <td style={{ fontWeight: 'bold' }}>${total}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
  );
}

export default Invoicesummary;