import React, { useEffect, useState } from 'react';
import './AssignmentDetails.css';
import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import { AssignmentService } from '../../Services/AssignmentService';
import _ from 'lodash';

function AssignmentDetails({ bpiNo, onContinue, activeTab }) {

  const teamWrkBpiNb = bpiNo;
  console.log(teamWrkBpiNb);
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const userInfo = getUserInfoFromSession();
  // const VinNumber = "1FTFW1E51MFC15439"

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assignmentResponse = await AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb);
        setAssignmentDetails(assignmentResponse.data);
        if (assignmentResponse.data.length && assignmentResponse.data[0].vinSerialNb) {
          AssignmentService.getBuyerCobuyerInfo(assignmentResponse.data[0].vinSerialNb)
            .then((buyerCoBuyerResponse) => {
              console.log(buyerCoBuyerResponse.data[0]);
              setBuyerCoBuyerInfo(buyerCoBuyerResponse.data);
            })
            .catch((err) => {
              console.log(`Error Fetching Buyer Co-Buyer Info: ${err}`);
            });
        }
      } catch (err) {
        console.log(`Error FectchingData : ${err}`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

  }, [activeTab]);

  if (loading)
    return (
      <>
        <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
        <br />
        Loading...
      </>
    );
  return (
    <>
      <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
      <br />
      <div>
        {!_.isEmpty(buyerCoBuyerInfo) ? _.sortBy(buyerCoBuyerInfo, [(info) => info.customerAccount.applicant.applicantCode !== 'PR', 'customerAccount.applicant.applicantCode']).map((buyer, index) => {
            let applicant = buyer.customerAccount.applicant;
            const { address, firstName1, lastName1,firstName3, lastName3,firstName2, lastName2, phone } = applicant;

            // Ensure that all parts of the name and address exist before rendering
            const fullName = `${firstName1} ${firstName2 || ""} ${firstName3 || ""} ${lastName3 || ""} ${lastName2 || ""} ${lastName1}`; // Assuming firstName1 and lastName1 are always present

            // Handle missing fields gracefully (e.g., phone or address)
            const fullAddress = address
              ? `${address.addressLine1} ${address.addressLine2 || ""} ${address.city} ${address.stateCode} ${address.otherCountry || ""} ${address.otherPostCode || ""} ${address.otherState || ""} ${address.postCode} ${address.country}`
              : 'Address not available';

            const phoneNumbers = phone
              ? `${phone.number1} ${phone.number2}` // Assuming phone is an object with number1 and number2 properties
              : 'Phone numbers not available';
            if (applicant.applicantCode === 'PR') {
              return (
                <section
                  key={index} // Use index or a unique id from your data instead of concatenated names
                  className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                >
                  <div className="fds-weight--bold">Buyer Information:</div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0">
                      <div className="fds-layout-grid__cell--span-12">
                        Name: {fullName}
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0">
                      <div className="fds-layout-grid__cell--span-6">
                        Address: {fullAddress}
                      </div>
                      <div className="fds-layout-grid__cell--span-6">Employer: {applicant.businessName}</div>
                    </div>
                  </div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0">
                      <div className="fds-layout-grid__cell--span-6">Phone: {phoneNumbers}</div>
                      <div className="fds-layout-grid__cell--span-6">Phone:</div>
                    </div>
                  </div>
                </section>
              );
            } else {
              return (
                <section key={applicant.firstName1 + '-' + applicant.firstName2 + '-' + applicant.firstName3}
                         className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                >
                  <div className="fds-weight--bold">Co-Buyer Information:</div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div
                        className="fds-layout-grid__cell--span-12">Name: {fullName}</div>
                    </div>
                  </div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div
                        className="fds-layout-grid__cell--span-6">Address: {fullAddress}</div>
                      <div className="fds-layout-grid__cell--span-6">Employer: {applicant.businessName}</div>
                    </div>
                  </div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">Phone:</div>
                      <div className="fds-layout-grid__cell--span-6">Phone:</div>
                    </div>
                  </div>
                </section>
              );
            }
          }) :
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div className="fds-weight--bold">Buyer Information:</div>
            <div className="fds-layout-grid p-0">
              <div className="fds-layout-grid__inner p-0">
                <div className="fds-layout-grid__cell--span-12">
                  Name:
                </div>
              </div>
            </div>
            <div className="fds-layout-grid p-0">
              <div className="fds-layout-grid__inner p-0">
                <div className="fds-layout-grid__cell--span-6">
                  Address:
                </div>
                <div className="fds-layout-grid__cell--span-6">Employer:</div>
              </div>
            </div>
            <div className="fds-layout-grid p-0">
              <div className="fds-layout-grid__inner p-0">
                <div className="fds-layout-grid__cell--span-6">Phone:</div>
                <div className="fds-layout-grid__cell--span-6">Phone:</div>
              </div>
            </div>
          </section>
        }
        <br />
        {
          _.map(assignmentDetails, (assignmentDetail) => (
            <section key={assignmentDetail.recvrMthdCdDs}
                     className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
            >
              <div className="fds-weight--bold">Assignment Information:</div>
              <div className="fds-layout-grid p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">Type of
                    surrender: {assignmentDetail.recvrMthdCdDs}</div>
                  <div
                    className="fds-layout-grid__cell--span-6">Fee: {assignmentDetail.acptCntrctrFeeAm}</div>
                </div>
              </div>
              <div className="fds-layout-grid p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">CSR Name: {assignmentDetail.reqtUserNm}</div>
                  <div className="fds-layout-grid__cell--span-6">Phone: {assignmentDetail.reqtUserPhnNb}</div>
                </div>
              </div>
              <div className="fds-layout-grid p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">Special Instructions /
                    Directions: {assignmentDetail.pckupInstrTxt}
                  </div>
                </div>
              </div>
            </section>
          ))
        }
        <br />
        <section>
          <div className="center">
            <button type="button" className="fmc-button" onClick={onContinue}>Continue
            </button>
          </div>
        </section>
      </div>
    </>
  )
    ;
}

export default AssignmentDetails;