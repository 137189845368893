import {savePPDetails, savePPLocStat} from "../api/personalproprty.api";

export const PersonalPropertySevice = {

    savePPDetails: async (ppDetails) => {
        return savePPDetails(ppDetails);
    },
    savePPLocStat: async (ppLocStat) => {
        return savePPLocStat(ppLocStat);
    }
}