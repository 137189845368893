import React, {useEffect, useState} from "react";
import {coverageService} from "../../Services/CoverageService";
import _ from "lodash";
import Header from "../../Components/Header/Header";
import {useNavigate, useParams} from "react-router-dom";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import Footer from "../../Components/Footer/Footer";
import {profileService} from "../../Services/ProfileService";
import '@ford/ford-design-system/dist/web-components.js';

export function ViewCoverage() {

    const [coverageList, setConverageList] = useState([]);
    const [personalDetail, setPersonalDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const {contractorNb} = useParams();
    const userInfo = getUserInfoFromSession();
    const nav = useNavigate();

    useEffect(() => {

        const fetchCoverageByCntrId = async () => {
            try {
                const response = await coverageService.getCoverageByContractorNb(contractorNb, userInfo.countryCd);
                setConverageList(response);

                const contractPersonalResp = await profileService.getContractorPersonal(contractorNb);
                setPersonalDetail(contractPersonalResp.data.result.cntrctrData2ApiList[0]);
            }catch (err) {
                console.error('Failed to fetch coverage with id' + err.message);
            }finally {
                setLoading(false)
            }
        };

        fetchCoverageByCntrId();
    }, []);

    const groupedData = _.chain(coverageList)
        .groupBy('prcStrctTx')
        .mapValues( value => _.groupBy(value, 'stateOrProv'))
        .value();

    const handleCancel = () => {
        nav('/contractorprofile');
    }
    if(loading) return <div>Loading...</div>;

    return (
        <div>
            <Header heading="View Coverage" summaryDisplay="none" profileDisplay="none"/>
            <div className="margin10">
                <table border="5" className="fmc-table">
                <tbody>
                    <tr>
                        <td>Company name:</td>
                        <td>{personalDetail?.cntrctrBusNm}</td>
                    </tr>
                {_.map(groupedData, (value, prcStrctTx) => (
                    <>
                        <tr>
                            <td style={{
                                width: '40%',
                                border: '1px solid black'
                            }}>Price Structure Name:
                            </td>
                            <td style={{
                                border: '1px solid black'
                            }}>{prcStrctTx}</td>
                        </tr>
                        {_.map(value, (provn, stateOrProv) => (
                            <>
                                <tr key="{stateOrProv}">
                                    <td style={{
                                        paddingLeft: '1em',
                                        width: '40%'
                                    }}>{userInfo.countryCd === 'USA' ? "State " : "Province "} :
                                    </td>
                                    <td style={{
                                        border: '1px solid black'
                                    }}>{stateOrProv}</td>
                                </tr>
                                <tr key="{stateOrProv}-county">
                                    <td style={{
                                        verticalAlign: 'top',
                                        paddingLeft: '2em',
                                        border: '1px solid black'
                                    }}>{userInfo.countryCd === 'USA' ? "Counties " : "Postal Codes "} :
                                    </td>
                                    <td style={{border: '1px solid black'}}>
                                        {provn.map((item, idx) => (
                                            <p key={item.countyOrPC}>{item.countyOrPC}</p>
                                        ))}
                                    </td>
                                </tr>
                            </>
                        ))}
                    </>
                ))}
                </tbody>
                </table>
            </div><br/><br/>
            <div style={{textAlign: "center"}}>
                <fmc-button name="Cancel" label="Cancel" onClick={handleCancel}/>
            </div>
            <Footer/>
        </div>
    )
}