import {PARAM_KEY} from "../constants/params";

export function setUserInfoToSession(sessionObj){
    const currTime = new Date().getTime();
    sessionStorage.setItem(PARAM_KEY.session.KEY1, JSON.stringify(sessionObj));
    const expirationTime = currTime + 20* 60* 1000;
    sessionStorage.setItem(PARAM_KEY.session.KEY_EXPIRE, expirationTime.toString());
}
export function setBuyerInfoToSession(sessionObj){
    const currTime = new Date().getTime();
    sessionStorage.setItem(PARAM_KEY.session.KEY2, JSON.stringify(sessionObj));
    const expirationTime = currTime + 20* 60* 1000;
    sessionStorage.setItem(PARAM_KEY.session.KEY_EXPIRE, expirationTime.toString());
}
export function setAssignmentInfoToSession(sessionObj){
    const currTime = new Date().getTime();
    sessionStorage.setItem(PARAM_KEY.session.KEY3, JSON.stringify(sessionObj));
    const expirationTime = currTime + 20* 60* 1000;
    sessionStorage.setItem(PARAM_KEY.session.KEY_EXPIRE, expirationTime.toString());
}

export function setPPropertyInfoToSession(sessionObj){
    const currTime = new Date().getTime();
    sessionStorage.setItem(PARAM_KEY.session.KEY4, JSON.stringify(sessionObj));
    const expirationTime = currTime + 20* 60* 1000;
    sessionStorage.setItem(PARAM_KEY.session.KEY_EXPIRE, expirationTime.toString());
}
export function getUserInfoFromSession(){
    const currTime = new Date().getTime();
    const tokenExpiration = sessionStorage.getItem(PARAM_KEY.session.KEY_EXPIRE);

    if(currTime > tokenExpiration) {
        sessionStorage.removeItem(PARAM_KEY.session.KEY1);
        sessionStorage.removeItem(PARAM_KEY.session.KEY_EXPIRE);
        return null;
    } else {
        return JSON.parse(sessionStorage.getItem(PARAM_KEY.session.KEY1));
    }
}
export function getBuyerInfoFromSession(){
    const currTime = new Date().getTime();
    const tokenExpiration = sessionStorage.getItem(PARAM_KEY.session.KEY_EXPIRE);

    if(currTime > tokenExpiration) {
        sessionStorage.removeItem(PARAM_KEY.session.KEY2);
        sessionStorage.removeItem(PARAM_KEY.session.KEY_EXPIRE);
        return null;
    } else {
        return JSON.parse(sessionStorage.getItem(PARAM_KEY.session.KEY2));
    }
}
export function getAssignmentInfoFromSession(){
    const currTime = new Date().getTime();
    const tokenExpiration = sessionStorage.getItem(PARAM_KEY.session.KEY_EXPIRE);

    if(currTime > tokenExpiration) {
        sessionStorage.removeItem(PARAM_KEY.session.KEY3);
        sessionStorage.removeItem(PARAM_KEY.session.KEY_EXPIRE);
        return null;
    } else {
        return JSON.parse(sessionStorage.getItem(PARAM_KEY.session.KEY3));
    }
}

export function getPPropertyInfoFromSession(){
    const currTime = new Date().getTime();
    const tokenExpiration = sessionStorage.getItem(PARAM_KEY.session.KEY_EXPIRE);

    if(currTime > tokenExpiration) {
        sessionStorage.removeItem(PARAM_KEY.session.KEY4);
        sessionStorage.removeItem(PARAM_KEY.session.KEY_EXPIRE);
        return null;
    } else {
        return JSON.parse(sessionStorage.getItem(PARAM_KEY.session.KEY4));
    }
}

export function updateUserInfoContryCd(countryCd){
    const uInfo = getUserInfoFromSession();
    uInfo.countryCd = countryCd;
    setUserInfoToSession(uInfo);
}
export function updateUserInfoContractorNb(cntrctrNb){
    const uInfo = getUserInfoFromSession();
    uInfo.cntrctrNb = cntrctrNb;
    setUserInfoToSession(uInfo);
}