//import { FMCAccordion } from './AccordionComponent'
import { FMCAccordion } from '@ford/ford-design-system';
import { useEffect, useRef, useState } from 'react';
import Button from '../../../Components/Button/Button';
import Label from '../../../Components/Label/Label';
import RadioButton from '../../../Components/RadioButton/RadioButton';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { getCall } from '../../../Services/CommonService';

const Accordion = (props) => {
  const initialized = useRef(false);
  const accordionRef = useRef();
  const [propStatSelect, setPropStatSelect] = useState({});

  const handleRadioChange = (event) => {
    setPropStatSelect(prevState => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const personalPropClick = (bpiNb) => {
    if (propStatSelect[bpiNb] !== 'R' && propStatSelect[bpiNb] !== 'D') {
      swal('', 'Must select Returned or Disposed to update', 'warning');
    } else {

      Promise.all([
          getCall('processProperty/updatePrsnPrptyStat' +
            '?bpiNb=' + bpiNb + '&cntrctrNb=' + props.userInfo.cntrctrNb + '&propStatCd=' + propStatSelect[bpiNb] + '&userId=' + props.userInfo.userId),
          getCall('processProperty/updatePrsnPrptyFupStat' +
            '?bpiNb=' + bpiNb + '&propStatCd=' + propStatSelect[bpiNb] + '&userId=' + props.userInfo.userId),
        ],
      ).then(([personalProperty, followUp]) => {
        console.log('Personal Property API Calls finished');
        console.log(personalProperty);
        console.log(followUp);
        swal('', 'Success', 'success');
      }).catch((error) => {
        console.log('Error calling updatePrsnPrptyStat or updatePrsnPrptyFupStat');
        swal('', 'Error', 'error');
      });
    }
  };

  useEffect(() => {
    props.panels.map((panel) => {
      if (panel.name === 'Personal Property') {
        panel.accounts.map((account) => {
          setPropStatSelect(prevState => ({
            ...prevState,
            [account.teamWrkBpiNb]: { 'propStatCd': '', 'invalid': null },
          }));
          return '';
        });
        return '';
      }
      return '';
    });

    if (!initialized.current) {
      initialized.current = true;
      new FMCAccordion(accordionRef.current);
    }
    return;
  }, []);

  const col10 = { width: '10%' };
  const col15 = { width: '15%' };
  const col20 = { width: '20%' };
  const col25 = { width: '25%' };

  const rbOptions = [{ name: 'Returned', value: 'R' }, { name: 'Disposed', value: 'D' }];

  return (
    <div ref={accordionRef} className="js-fmc-accordion fmc-accordion fds-color__bg--light">
      {
        props.panels > 0 ? 'Loading...' : props.panels.map((panel, i) => (
          <div className="fmc-accordion__panel" key={i}>
            <button type="button" className="fmc-accordion__button" id="panel-1-button"
                    aria-controls="panel-1-content">
              <span className="fmc-accordion__button-title">{panel.name}</span>
              <span className="fmc-accordion__button-expand fds-icon"></span>
            </button>
            <div className="fmc-accordion__body">
              <div
                className="fmc-accordion__content"
                aria-labelledby="panel-1-button"
                id="panel-1-content"
                role="region"
                aria-hidden="false"
              >
                <table className="fmc-type--body1 panelTable">
                  <colgroup>
                    <col style={col15} />
                    <col style={col20} />
                    <col style={col10} />
                    <col style={col25} />
                    <col style={col10} />
                    <col style={col10} />
                    <col style={col10} />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Account</th>
                    <th>Vehicle Info</th>
                    <th>VIN</th>
                    <th>RDN Case</th>
                  </tr>
                  </thead>
                  {
                    panel.accounts.map((account, j) =>
                      <tbody key={j}>
                      <tr>
                        {panel.name === 'Active' && props.userInfo.rdnInd === 0 ? (
                          <>
                            <td className="dateCol"><Link
                              to={`/main/${account.teamWrkBpiNb}`}>{account.cntrctrAcptDt}</Link>
                            </td>
                            <td className="nameCol"><Link
                              to={`/main/${account.teamWrkBpiNb}`}>{account.lastNm}</Link>
                            </td>
                            <td className="numCol"><Link
                              to={`/main/${account.teamWrkBpiNb}`}>{account.custAcctNb}</Link>
                            </td>
                            <td className="vehCol"><Link
                              to={`/main/${account.teamWrkBpiNb}`}><span>{account.vehMdlYr}</span>&nbsp;
                              <span>{account.vehColorTx}</span>&nbsp;
                              <span>{account.makeDescTx}</span>&nbsp;
                              <span>{account.mdlDescTx}</span></Link>
                            </td>
                            <td className="vinCol"><Link
                              to={`/main/${account.teamWrkBpiNb}`}>{account.vinSerialNb}</Link>
                            </td>
                            <td className="caseCol"><Link
                              to={`/main/${account.teamWrkBpiNb}`}>{account.caseId}</Link>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="dateCol">{account.cntrctrAcptDt}</td>
                            <td className="nameCol">{account.lastNm}</td>
                            <td className="numCol">{account.custAcctNb}</td>
                            <td className="vehCol"><span>{account.vehMdlYr}</span>&nbsp;
                              <span>{account.vehColorTx}</span>&nbsp;
                              <span>{account.makeDescTx}</span>&nbsp;
                              <span>{account.mdlDescTx}</span>
                            </td>
                            <td className="vinCol">{account.vinSerialNb}</td>
                            <td className="caseCol">{account.caseId}</td>
                          </>
                        )}
                        <td>{
                          // panel.name === "Active" ? (
                          //     <Button label="Update"
                          //             onClick={() => activeClick(account.teamWrkBpiNb)}/>
                          // ) :
                          panel.name === 'Personal Property' && props.userInfo.rdnInd === 0 ? (
                            <Button label="Update"
                                    onClick={() => personalPropClick(account.teamWrkBpiNb)} />
                          ) : (
                            <></>
                          )
                        }</td>
                      </tr>
                      {
                        panel.name === 'Personal Property' &&
                        <tr>
                          <td></td>
                          <td></td>
                          <td colSpan="2"><Label
                            text="Property: " /> State: {account.vehRcvrdStAbbrCd} Age: {account.age}
                          </td>
                          <td>
                            {
                            props.userInfo.rdnInd === 0 &&
                            <RadioButton label=""
                                           options={rbOptions}
                                           groupName={`${account.teamWrkBpiNb}`}
                                           radioVal={propStatSelect[`${account.teamWrkBpiNb}`]}
                                           className="fmc-check--horizontal marginMinus20"
                                           onChange={handleRadioChange} />
                            }
                          </td>
                          <td></td>
                        </tr>
                      }
                      </tbody>
                    )
                  }

                </table>
              </div>
            </div>
          </div>
        ))}

    </div>
  );
};

export default Accordion;