import {
    CreateVehicleDisposalInfo,
    getAuctionLocationList,
    getVCRPDFbytes,
    getVechileDisposalInfo,
    UpdateVehicleDisposalInfo,
} from '../api/assignmentsummary.api.js';

export const AssignmentSummaryService = {

    getVechileDisposalInfo : async (customerAccoutNumber,cobusCode,origBranch) => {
        return getVechileDisposalInfo(customerAccoutNumber,cobusCode,origBranch);
    },
    CreateVehicleDisposalInfo: async (insertvehicladisposaldata) => {
        return CreateVehicleDisposalInfo(insertvehicladisposaldata);
    },
    UpdateVehicleDisposalInfo: async (customerAccoutNumber,cobusCode,origBranch,updatevehicleDisposaldata1) => {
        return UpdateVehicleDisposalInfo(customerAccoutNumber,cobusCode,origBranch,updatevehicleDisposaldata1);
    },
    getAuctionLocationList :async(countrycode) =>{
        return getAuctionLocationList();
    },
    getVCRPDFbytes :async(vcrarchiveObject) =>{
        return getVCRPDFbytes(vcrarchiveObject);
    }
}
