import { ENDPOINTS } from '../constants/endpoints';
import axios from 'axios';

const serviceAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_BASE_URL_FCNA,
  withCredentials: true,
});

export function getVechileDisposalInfo(customerAccoutNumber, cobusCode, origBranch) {
  return serviceAxiosInstance.get(ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL'] + cobusCode + '/' + origBranch + '/' + customerAccoutNumber);
}

export function CreateVehicleDisposalInfo(insertvehicladisposaldata) {
  return serviceAxiosInstance.post(ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL'], insertvehicladisposaldata);
}

export function UpdateVehicleDisposalInfo(customerAccoutNumber, cobusCode, origBranch, updatevehicleDisposaldata1) {
  return serviceAxiosInstance.put(ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL'] + cobusCode + '/' + origBranch + '/' + customerAccoutNumber, updatevehicleDisposaldata1);
}

export function getAuctionLocationList(countrycode) {
  return serviceAxiosInstance.get(ENDPOINTS['GET_AUCTION_LOCATION'] + countrycode);
}

export function getVCRPDFbytes(vcrarchiveObject){
  return axios.post(process.env.REACT_APP_SERVICE_BASE_URL + ENDPOINTS['GET_VCR_PDFBYTEARRAY'], vcrarchiveObject);
}


