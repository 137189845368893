import React from 'react';
function Footer() {
    return (
        <>
            <br/><div className="fmc-bottom-banner" >
    <div className="fmc-bottom-banner__content center" >Copyright &copy; 2024 Ford Motor Credit Company. All Rights Reserved</div>

</div></>);
}

export default Footer