import Header from '../../Components/Header/Header';
import React, { useEffect, useState } from 'react';
import './CancelAssignment.css';
import { putCall } from '../../Services/CommonService';
import { useNavigate, useParams } from 'react-router-dom';
import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import * as Constants from '../../constants/RepoReactConstants';
import { PINNCALE_LOG_OBJ } from '../../constants/commonJsonConstants';

import { CommonDataService, getcommentStr } from '../../Services/CommonDataService';
import { FcnaDB2Postcall } from '../../api/accountsummary.api';
import { AssignmentService } from '../../Services/AssignmentService';
import _ from 'lodash';

function CancelAssignment(props){

    const navigate = useNavigate();
    let { teamWrkBpiNb } = useParams();
    console.log(teamWrkBpiNb)

    const [accountInformation, setAccountInformation] = useState([]);
    const [commonRepoInformation, setCommonRepoInformation] = useState([]);
    const [contractorInfoForComment, setContractorInfoForComment] = useState([]);

    const [assignmentDetails, setAssignmentDetails] = useState([])
    const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([])
    const userInfo = getUserInfoFromSession()
    function getCurrentTimestamp() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    useEffect( () => {

        const fetchcommonInfo = async () => {
            try {
                Promise.all([
                    CommonDataService.getAccountInfo(teamWrkBpiNb, userInfo.cntrctrNb),
                    CommonDataService.getRepoCommonInfo(teamWrkBpiNb, userInfo.cntrctrNb),
                    CommonDataService.getCntrctrInfoForComments(userInfo.userid),
                    AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb)
                ])
                    .then(([accountInfoResponse, commonRepoInfoResponse, contractorInfoForCommentResponse,
                               assignmentResponse]) => {
                        setAccountInformation(accountInfoResponse.data);
                        setCommonRepoInformation(commonRepoInfoResponse.data);
                        setContractorInfoForComment(contractorInfoForCommentResponse.data);
                        setAssignmentDetails(assignmentResponse.data);
                        //get buyerInfo by assginmentDetail
                        AssignmentService.getBuyerCobuyerInfo(assignmentResponse.data[0].vinSerialNb)
                            .then((buyerInfoResp) => {
                                setBuyerCoBuyerInfo(buyerInfoResp.data);
                            })
                            .catch((error) => {
                                console.error('AssignmentService.getBuyerCobuyerInfo error:: ', error)
                            });
                    })
                    .catch((error) => {
                        console.error('An error occurred:', error)
                    });
            } catch (error) {
                // Handle any errors that occur in any of the promises
                console.error('An error occurred:', error);
            }
        };
        fetchcommonInfo();

    }, [teamWrkBpiNb]);



    const  logPinnacle = (accountInformation,contractorInfoForComment,PINNCALE_LOG_OBJ) =>
    {
        PINNCALE_LOG_OBJ.receivableAccountNumber = accountInformation.custAcctNb;
        PINNCALE_LOG_OBJ.coBusCd = accountInformation.coBusCd;
        PINNCALE_LOG_OBJ.createdTimeStamp = getCurrentTimestamp();
        PINNCALE_LOG_OBJ.enteredBranchCode = accountInformation.superBranchCd;
        PINNCALE_LOG_OBJ.createdBy = userInfo.userid;
        console.log(" PINNCALE  LOG Object to Log before add comment :::::::" , PINNCALE_LOG_OBJ) ;

        console.log("comment String with pinnalce:::::::::::::::::::",getcommentStr(contractorInfoForComment,Constants.ACTION_CANCEL,Constants.rsCANCEL_CONFIRM,userInfo.userid));
        PINNCALE_LOG_OBJ.comment=getcommentStr(contractorInfoForComment,Constants.ACTION_CANCEL,Constants.rsCANCEL_CONFIRM,userInfo.userid);
        console.log(" after comment set:::::::::::::::::::",PINNCALE_LOG_OBJ);

        return CommonDataService.createPinnacleContact(PINNCALE_LOG_OBJ);
    }


    const onclickHandler=()=>{
        Promise.all([
          logPinnacle(accountInformation,contractorInfoForComment,PINNCALE_LOG_OBJ),
          putCall("processQuestion/updateCntrctAcct?bpiNb="+teamWrkBpiNb+"&cntrctrNb="+userInfo.cntrctrNb+"&sConfirm=Y&sCsrAction=C"),
          FcnaDB2Postcall({
            'acctNb': accountInformation?.custAcctNb,
            'coBusCd': accountInformation?.coBusCd,
            'transTypeCd': 21,
            'sourceSystemCode': 12,
            'lastUpdatedUserIdentificationCode': userInfo.userid,
            'transactionDataText': teamWrkBpiNb + ';CANCEL_CONFIRM',
            'isImmediate': true,
          })
        ]).then(([pinnResp,putResp,fcnadb2Resp]) => {
          console.log('Pinnacle Response:', JSON.stringify(pinnResp));
          console.log('putCall Response:', JSON.stringify(putResp));
          console.log('FcnaDB2Postcall Response:', JSON.stringify(fcnadb2Resp));
        }).catch((error) => {
          console.error('Error in Promise.all:', error);
        }).finally(() => {
          navigate('/outstandingAssignment');
        });
      }


    return(
        <>
            <Header heading="Cancel Assignment" />
            <br/>
            <AssignmentDetailsHeader bpiNo = {teamWrkBpiNb}/>
            <br/>
            <div>
              {!_.isEmpty(buyerCoBuyerInfo) ? _.sortBy(buyerCoBuyerInfo, [(info) => info.customerAccount.applicant.applicantCode !== 'PR', 'customerAccount.applicant.applicantCode']).map((buyer, index) => {
                  let applicant = buyer.customerAccount.applicant;
                  const { address, firstName1, lastName1,firstName3, lastName3,firstName2, lastName2, phone } = applicant;

                  // Ensure that all parts of the name and address exist before rendering
                  const fullName = `${firstName1} ${firstName2 || ""} ${firstName3 || ""} ${lastName3 || ""} ${lastName2 || ""} ${lastName1}`; // Assuming firstName1 and lastName1 are always present

                  // Handle missing fields gracefully (e.g., phone or address)
                  const fullAddress = address
                    ? `${address.addressLine1} ${address.addressLine2 || ""} ${address.city} ${address.stateCode} ${address.otherCountry || ""} ${address.otherPostCode || ""} ${address.otherState || ""} ${address.postCode} ${address.country}`
                    : 'Address not available';

                  const phoneNumbers = phone
                    ? `${phone.number1} ${phone.number2}` // Assuming phone is an object with number1 and number2 properties
                    : 'Phone numbers not available';
                  if (applicant.applicantCode === 'PR') {
                    return (
                      <section
                        key={index} // Use index or a unique id from your data instead of concatenated names
                        className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                      >
                        <div className="fds-weight--bold">Buyer Information:</div>
                        <div className="fds-layout-grid p-0">
                          <div className="fds-layout-grid__inner p-0">
                            <div className="fds-layout-grid__cell--span-12">
                              Name: {fullName}
                            </div>
                          </div>
                        </div>
                        <div className="fds-layout-grid p-0">
                          <div className="fds-layout-grid__inner p-0">
                            <div className="fds-layout-grid__cell--span-6">
                              Address: {fullAddress}
                            </div>
                            <div className="fds-layout-grid__cell--span-6">Employer: {applicant.businessName || ""}</div>
                          </div>
                        </div>
                        <div className="fds-layout-grid p-0">
                          <div className="fds-layout-grid__inner p-0">
                            <div className="fds-layout-grid__cell--span-6">Phone: {phoneNumbers || ""}</div>
                            <div className="fds-layout-grid__cell--span-6">Phone:</div>
                          </div>
                        </div>
                      </section>
                    );
                  } else {
                    return (
                      <section key={applicant.firstName1 + '-' + applicant.firstName2 + '-' + applicant.firstName3}
                               className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                      >
                        <div className="fds-weight--bold">Co-Buyer Information:</div>
                        <div className="fds-layout-grid p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div
                              className="fds-layout-grid__cell--span-12">Name: {fullName}</div>
                          </div>
                        </div>
                        <div className="fds-layout-grid p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">Address: {fullAddress}</div>
                            <div className="fds-layout-grid__cell--span-6">Employer: {applicant.businessName || ""}</div>
                          </div>
                        </div>
                        <div className="fds-layout-grid p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">Phone:</div>
                            <div className="fds-layout-grid__cell--span-6">Phone:</div>
                          </div>
                        </div>
                      </section>
                    );
                  }
                }) :
                <section
                  className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                >
                  <div className="fds-weight--bold">Buyer Information:</div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0">
                      <div className="fds-layout-grid__cell--span-12">
                        Name:
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0">
                      <div className="fds-layout-grid__cell--span-6">
                        Address:
                      </div>
                      <div className="fds-layout-grid__cell--span-6">Employer:</div>
                    </div>
                  </div>
                  <div className="fds-layout-grid p-0">
                    <div className="fds-layout-grid__inner p-0">
                      <div className="fds-layout-grid__cell--span-6">Phone:</div>
                      <div className="fds-layout-grid__cell--span-6">Phone:</div>
                    </div>
                  </div>
                </section>
              }
              <br />
              {
                _.map(assignmentDetails, (assignmentDetail) => (
                  <section key={assignmentDetail.recvrMthdCdDs}
                           className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                  >
                    <div className="fds-weight--bold">Assignment Information:</div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">Type of
                          surrender: {assignmentDetail.recvrMthdCdDs}</div>
                        <div
                          className="fds-layout-grid__cell--span-6">Fee: {assignmentDetail.acptCntrctrFeeAm}</div>
                      </div>
                    </div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">CSR Name: {assignmentDetail.reqtUserNm}</div>
                        <div className="fds-layout-grid__cell--span-6">Phone: {assignmentDetail.reqtUserPhnNb}</div>
                      </div>
                    </div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">Special Instructions /
                          Directions: {assignmentDetail.pckupInstrTxt}
                        </div>
                      </div>
                    </div>
                  </section>
                ))
              }
              <br />
              <section>
                <div className="center">
                  <button type="button" className="fmc-button" onClick={onclickHandler}>Acknowledge</button>
                </div>
              </section>
            </div>

        </>

    )
}

export default CancelAssignment;