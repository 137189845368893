import './Profile.css';
import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import { getCall, putCall } from '../../Services/CommonService';
import '@ford/ford-design-system/dist/web-components.js';
import { coverageService } from '../../Services/CoverageService';
import _ from 'lodash';
import { profileService } from '../../Services/ProfileService';
import { showAlert } from '../../Services/CommonFunctions';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const [Contacts, setContacts] = useState([]);
  const [CntrPersonalDetail, setCntrPersonalDetail] = useState([]);
  const [StatesOrProvince, setStatesOrProvince] = useState([]);
  const [GroupedCoverages, setGroupedCoverages] = useState([]);
  const [remainingChars, setRemainingChars] = useState(255);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const userInfo = getUserInfoFromSession();

  const countCharacters = (e) => {
    const value = e.target.value;
    setCntrPersonalDetail((prevCntrPersonalDetail) => ({
      ...prevCntrPersonalDetail,
      othrInfoTx: value,
    }));
    setRemainingChars(255 - value.length);
  };

  const updateContactAreaCd = (cntctCd, e) => {
    const value = e.target.value;
    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.cntctCd === cntctCd
          ? {
            ...contact,
            phnCityAreaCd: value,
          }
          : contact,
      ),
    );
  };

  const updateContactPhoneNb = (cntctCd, e) => {
    const value = e.target.value;
    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.cntctCd === cntctCd
          ? {
            ...contact,
            phnNb: value,
          }
          : contact,
      ),
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.all([
          // getCall('SqlMgr/getCntrctrProfile/' + userInfo.userid),
          getCall('profileMaint/getContractorContact/' + userInfo.cntrctrNb),
          getCall('modifyContractorProfile/getContractorPersonalDetails/' + userInfo.cntrctrNb),
          coverageService.getStateOrProvinceByStAbbr(userInfo.countryCd),
          coverageService.getCoverageByContractorNb(userInfo.cntrctrNb, userInfo.countryCd),
        ]).then(([//contractorResponse,
                   contactResponse,
                   cntrPersonalDetailsResponse,
                   stateOrProviceResponse,
                   coveragesResponse]) => {
          // setContractorProfile(contractorResponse.data[0]);
          setContacts(contactResponse.data);
          setCntrPersonalDetail(cntrPersonalDetailsResponse.data.result.cntrctrData2ApiList[0]);
          setStatesOrProvince(stateOrProviceResponse);
          const groupedData = _.chain(coveragesResponse)
            .groupBy('prcStrctTx')
            .mapValues(value => _.groupBy(value, 'stateOrProv'))
            .value();
          setGroupedCoverages(groupedData);
          //set otherComm char counts
          setRemainingChars(255 - cntrPersonalDetailsResponse.data.result.cntrctrData2ApiList[0].othrInfoTx?.length);
        }).catch((error) => {
          console.error(error);
        });
      } catch (error) {
        console.error('Failed to fetch states:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  function update() {
    const personalDetailUpdate = _.pick(CntrPersonalDetail, [
      'addrLine1Tx', 'alrtPrfrncCd', 'cityNm', 'emailAddrTx',
      'gsdbCd', 'othrInfoTx', 'prmryCntctNm',
      'rdnInd', 'scndryCntctNm', 'stAbbrCd', 'zipCd',
    ]);
    console.log('personalDetailUpdate : ' + JSON.stringify(personalDetailUpdate));
    Promise.all([
      putCall('profileMaint/updateContractorData/' + userInfo.userid, personalDetailUpdate),
      profileService.updateContacts(Contacts, userInfo.cntrctrNb, userInfo.userid),
    ]).then(([psnDetailResponse, contactsResponse]) => {
      showAlert('Profile Saved successfully!!', 'success')
        .then(() => {
          navigate('/misc');
        });
    }).catch((error) => {
      console.log(error);
    });
  }

  return (loading ? <div>Loading...</div> :
      <>
        <Header heading="Profile" />
        <br />
        <div className="details_cntr">
          <img
            className="fmc-top-banner__demo-billboard"
            src={process.env.PUBLIC_URL + '/Assets/Profile.png'}
            alt=""
            height="100px"
            width="230px"
          />

          <div className="fds-layout-gri p-0">
            <div className="fds-layout-grid__inner p-0 ">
              <div className="fds-layout-grid__cell--span-6">
                <div className="fds-layout-gri p-0">
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-4">Name:</div>
                    <div className="fds-layout-grid__cell--span-8">
                      {CntrPersonalDetail.cntrctrBusNm}
                    </div>
                  </div>
                  <br />
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-4">Address:</div>
                    <div className="fds-layout-grid__cell--span-8">
                      <fmc-input
                        name="field"
                        value={CntrPersonalDetail.addrLine1Tx}
                        onInput={(e) => setCntrPersonalDetail(prevState => ({
                          ...prevState,
                          addrLine1Tx: e.target.value,
                        }))}
                      ></fmc-input>
                      <fmc-input
                        name="field"
                        value={CntrPersonalDetail.cityNm}
                        onInput={(e) => setCntrPersonalDetail(prevState => ({
                          ...prevState,
                          cityNm: e.target.value,
                        }))}
                      ></fmc-input>

                      <div className="fds-layout-gri p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                          <div className="fds-layout-grid__cell--span-6">
                            <select
                              className="fmc-select"
                              slot="input"
                              name="select-field"
                              status="standard"
                              value={CntrPersonalDetail.stAbbrCd}
                              required
                              onChange={(e) => setCntrPersonalDetail(prevState => ({
                                ...prevState,
                                stAbbrCd: e.target.value,
                              }))}
                            >
                              {StatesOrProvince.map((item) => (
                                <option key={item.stAbbrCd} value={item.stAbbrCd}>
                                  {item.stAbbrCd}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="fds-layout-grid__cell--span-6">
                            <fmc-input
                              name="field"
                              value={CntrPersonalDetail.zipCd}
                              onInput={(e) => setCntrPersonalDetail(prevState => ({
                                ...prevState,
                                zipCd: e.target.value,
                              }))}
                            ></fmc-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-4">
                      Fedrel Tax Id:
                    </div>
                    <div className="fds-layout-grid__cell--span-8">{CntrPersonalDetail.taxNb}</div>
                  </div>
                  <br />
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-4">
                      Alert Preference:
                    </div>
                    <div className="fds-layout-grid__cell--span-8">
                      <select
                        name="alertPreference"
                        id="vehicle-select"
                        className="fmc-select"
                        value={CntrPersonalDetail.alrtPrfrncCd}
                        onInput={(e) => setCntrPersonalDetail(prevState => ({
                          ...prevState,
                          alrtPrfrncCd: e.target.value,
                        }))}
                      >
                        <option label="Email" value="E">
                          Email
                        </option>
                        <option label="Both Email and Text" value="B">
                          Both Email and Text
                        </option>
                        <option label="Text via Phone" value="T">
                          Text via Phone
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-4">
                      Email Addresess:
                    </div>
                    <div className="fds-layout-grid__cell--span-8">
                      <fmc-input name="field" value={CntrPersonalDetail.emailAddrTx}
                                 onInput={(e) => setCntrPersonalDetail(prevState => ({
                                   ...prevState,
                                   emailAddrTx: e.target.value,
                                 }))}>
                      </fmc-input>
                    </div>
                  </div>
                  <br />
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-4">
                      Primary Contact:
                    </div>
                    <div className="fds-layout-grid__cell--span-8">
                      <fmc-input name="field" value={CntrPersonalDetail.prmryCntctNm}
                                 onInput={(e) => setCntrPersonalDetail(prevState => ({
                                   ...prevState,
                                   prmryCntctNm: e.target.value,
                                 }))}></fmc-input>
                    </div>
                  </div>
                  <br />
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-4">
                      Secondary Contact:
                    </div>
                    <div className="fds-layout-grid__cell--span-8">
                      <fmc-input
                        name="field"
                        value={CntrPersonalDetail.scndryCntctNm}
                        onInput={(e) => setCntrPersonalDetail(prevState => ({
                          ...prevState,
                          scndryCntctNm: e.target.value,
                        }))}
                        >
                      </fmc-input>
                    </div>
                  </div>
                  <br />
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-4">Other:</div>
                    <div className="fds-layout-grid__cell--span-8">
                      <fmc-textarea
                        maxLength="255"
                        name="field"
                        value={CntrPersonalDetail.othrInfoTx}
                        onKeyUp={countCharacters}
                        onFocus={countCharacters}
                        onPaste={countCharacters}
                      ></fmc-textarea>
                      Remaining characters: {remainingChars} of 255
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <div className="fds-layout-grid__cell--span-6">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Primary Phone:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        {
                          Contacts.filter((contact) => contact.cntctCd === 1).map((contact) => (
                            <>
                              <div className="fds-layout-grid__cell--span-4">
                                <fmc-input
                                  maxLength="3"
                                  onBlur={(elm) => updateContactAreaCd('1', elm)}
                                  name="field"
                                  limit="3n"
                                  value={contact.phnCityAreaCd}
                                ></fmc-input>
                              </div>
                              <div className="fds-layout-grid__cell--span-8">
                                <fmc-input
                                  name="field"
                                  value={contact.phnNb}
                                  maxLength="7"
                                  onBlur={(elm) => updateContactPhoneNb('1', elm)}
                                ></fmc-input>
                              </div>
                            </>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Fax Number:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        {
                          Contacts.filter((contact) => contact.cntctCd === 2).map((contact) => (
                            <>
                              <div className="fds-layout-grid__cell--span-4">
                                <fmc-input
                                  maxLength="3"
                                  onBlur={(elm) => updateContactAreaCd('2', elm)}
                                  name="field"
                                  limit="3n"
                                  value={contact.phnCityAreaCd}
                                ></fmc-input>
                              </div>
                              <div className="fds-layout-grid__cell--span-8">
                                <fmc-input
                                  name="field"
                                  value={contact.phnNb}
                                  maxLength="7"
                                  onBlur={(elm) => updateContactPhoneNb('2', elm)}
                                ></fmc-input>
                              </div>
                            </>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    After Hours Phone:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        {
                          Contacts.filter((contact) => contact.cntctCd === 7).map((contact) => (
                            <>
                              <div className="fds-layout-grid__cell--span-4">
                                <fmc-input
                                  maxLength="3"
                                  onBlur={(elm) => updateContactAreaCd('7', elm)}
                                  name="field"
                                  limit="3n"
                                  value={contact.phnCityAreaCd}
                                ></fmc-input>
                              </div>
                              <div className="fds-layout-grid__cell--span-8">
                                <fmc-input
                                  name="field"
                                  value={contact.phnNb}
                                  maxLength="7"
                                  onBlur={(elm) => updateContactPhoneNb('7', elm)}
                                ></fmc-input>
                              </div>
                            </>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Mobile Phone:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        {
                          Contacts.filter((contact) => contact.cntctCd === 4).map((contact) => (
                            <>
                              <div className="fds-layout-grid__cell--span-4">
                                <fmc-input
                                  maxLength="3"
                                  onBlur={(elm) => updateContactAreaCd('4', elm)}
                                  name="field"
                                  limit="3n"
                                  value={contact.phnCityAreaCd}
                                ></fmc-input>
                              </div>
                              <div className="fds-layout-grid__cell--span-8">
                                <fmc-input
                                  name="field"
                                  value={contact.phnNb}
                                  maxLength="7"
                                  onBlur={(elm) => updateContactPhoneNb('4', elm)}
                                ></fmc-input>
                              </div>
                            </>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Primary Text Phone:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        {
                          Contacts.filter((contact) => contact.cntctCd === 5).map((contact) => (
                            <>
                              <div className="fds-layout-grid__cell--span-4">
                                <fmc-input
                                  maxLength="3"
                                  onBlur={(elm) => updateContactAreaCd('5', elm)}
                                  name="field"
                                  limit="3n"
                                  value={contact.phnCityAreaCd}
                                ></fmc-input>
                              </div>
                              <div className="fds-layout-grid__cell--span-8">
                                <fmc-input
                                  name="field"
                                  value={contact.phnNb}
                                  maxLength="7"
                                  onBlur={(elm) => updateContactPhoneNb('5', elm)}
                                ></fmc-input>
                              </div>
                            </>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Additional Phones:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    {Contacts.filter(c => [6, 8, 9, 10].includes(c.cntctCd)).map((contact) => (
                      <>
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-8">
                              <select
                                slot="input"
                                name="select-field"
                                status="standard"
                                value={contact.cntctCd}
                                className="fmc-select"
                                required
                              >
                                <option label="" value=""></option>
                                <option label="Other Mobile Phone" key="6" value="6">
                                  Other Mobile Phone
                                </option>
                                <option label="Other Text Phone" key="7" value="7">
                                  Other Text Phone
                                </option>
                                <option label="Phone" key="8" value="8">
                                  Phone
                                </option>
                                <option label="Fax Phone" key="9" value="9">
                                  Fax Phone
                                </option>
                              </select>
                            </div>
                            <div className="fds-layout-grid__cell--span-4">
                              <fmc-input
                                name="field"
                                maxLength="3"
                                value={contact.phnCityAreaCd}
                                onBlur={(elm) => updateContactAreaCd(contact.cntctCd, elm)}
                              ></fmc-input>
                            </div>
                          </div>
                        </div>
                        <fmc-input
                          name="field"
                          maxLength="7"
                          value={contact.phnNb}
                          onBlur={(elm) => updateContactPhoneNb(contact.cntctCd, elm)}
                        ></fmc-input>
                      </>
                    ))}
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="fds-layout-grid fmc-pa-3 dividerdiv">
            <div className="fds-layout-grid__inner">
              <div
                className="
      fds-layout-grid__cell--span-12
      fmc-pb-3
      fmc-pb-5-md
      fmc-divider--bottom fmc-divider--top
    "
              >
                <div className="fds-weight--bold"></div>
                <div className="fds-layout-gri p-0">
                  <div className="fds-layout-grid__inner p-0 "></div>
                </div>
                <div className="fds-layout-gri p-0">
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-6">
                      <div className="fds-layout-gri p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                          <div className="fds-layout-grid__cell--span-4">
                            Effective Info:
                          </div>
                          <div className="fds-layout-grid__cell--span-6">
                            {CntrPersonalDetail.effDt}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fds-layout-grid__cell--span-6">
                      <div className="fds-layout-gri p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                          <div className="fds-layout-grid__cell--span-4">
                            Expires:
                          </div>
                          <div className="fds-layout-grid__cell--span-6">
                            {CntrPersonalDetail.expirDt}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fds-layout-grid__cell--span-12 fmc-pt-1-md fmc-divider--bottom">
                <div className="fds-layout-gri p-0">
                  <div className="fds-layout-grid__inner p-0 "></div>
                </div>
                <div className="fds-layout-gri p-0">
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-6">
                      <div className="fds-layout-gri p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                          <div className="fds-layout-grid__cell--span-4">
                            Insurance Info:
                          </div>
                          <div className="fds-layout-grid__cell--span-6">
                            {CntrPersonalDetail.insInfoTx}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fds-layout-grid__cell--span-6">
                      <div className="fds-layout-gri p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                          <div className="fds-layout-grid__cell--span-4">
                            Expires:
                          </div>
                          <div className="fds-layout-grid__cell--span-6">
                            {CntrPersonalDetail.insInfoExpirDt}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="fds-layout-gri p-0">
                  <div className="fds-layout-grid__inner p-0 ">
                    <div className="fds-layout-grid__cell--span-6">
                      <div className="fds-layout-gri p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                          <div className="fds-layout-grid__cell--span-4">
                            Business License:
                          </div>
                          <div className="fds-layout-grid__cell--span-6">
                            {CntrPersonalDetail.busLcnsTx}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fds-layout-grid__cell--span-6">
                      <div className="fds-layout-gri p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                          <div className="fds-layout-grid__cell--span-4">
                            Expires:
                          </div>
                          <div className="fds-layout-grid__cell--span-6">
                            {CntrPersonalDetail.busLcnsExpirDt}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="margin10">
            <div className="fds-layout-grid__cell--span-12 fmc-pt-1-md">
              <div className="fds-weight--bold"></div>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 "></div>
              </div>
              {_.map(GroupedCoverages, (value, prcStrctTx) => (
                <>
                  <tr>
                    <td style={{
                      width: '40%',
                      border: '1px solid black',
                    }}>Price Structure Name:
                    </td>
                    <td style={{
                      border: '1px solid black',
                    }}>{prcStrctTx}</td>
                  </tr>
                  {_.map(value, (provn, stateOrProv) => (
                    <>
                      <tr key="{stateOrProv}">
                        <td style={{
                          paddingLeft: '1em',
                          width: '40%',
                        }}>{userInfo.countryCd === 'USA' ? 'State ' : 'Province '} :
                        </td>
                        <td style={{
                          border: '1px solid black',
                        }}>{stateOrProv}</td>
                      </tr>
                      <tr key="{stateOrProv}-county">
                        <td style={{
                          verticalAlign: 'top',
                          paddingLeft: '2em',
                          border: '1px solid black',
                        }}>{userInfo.countryCd === 'USA' ? 'Counties ' : 'Postal Codes '} :
                        </td>
                        <td style={{ border: '1px solid black' }}>
                          {provn.map((item, idx) => (
                            <p key={item.countyOrPC}>{item.countyOrPC}</p>
                          ))}
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              ))}
            </div>
          </div>
          <section>
            <div className="center">
              <fmc-button label="Update" onClick={update}></fmc-button>
            </div>
          </section>
        </div>
      </>
  );
}

export default Profile;
