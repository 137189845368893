import './VehicleConditionReport.css';
import React, { useEffect, useState } from 'react';
import { validatevcr } from './VCRFormValidation';
import { showAlert, showConfirm } from '../../Services/CommonFunctions';
import AssignmentDetailsHeader from '../common/AssignmentDetailsHeader';
import { VCRService } from '../../Services/VCRService';
import { AssignmentService } from '../../Services/AssignmentService';
import { getUserInfoFromSession } from '../../Services/session.mgmt';
import Header from '../../Components/Header/Header';
import _ from 'lodash';

function VehicleConditionReport(props) {

  const userInfo = getUserInfoFromSession();
  let teamWrkBpiNb = Number(props.bpiNo);
  console.log(teamWrkBpiNb);
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([]);
  const [TireConditionOptions, setTireConditionOptions] = useState([]);
  const [OverallConditionOptions, setOverallConditionOptions] = useState([]);
  const [GeneralConditionOptions, setGeneralConditionOptions] = useState([]);
  const [InteriorConditionOptions, setInteriorConditionOptions] = useState([]);
  const [GlassConditionOptions, setGlassConditionOptions] = useState({});
  const [MechanicalConditionOptions, setMechanicalConditionOptions] = useState([]);
  const [AuctionLocationList, setAuctionLocationList] = useState([]);
  const [VcrFromData, setVcrFormData] = useState({});
  const [VehLocData, setVehLocData] = useState({});
  const [StateOrProvince, setStateOrProvince] = useState([]);
  const [Error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [showBuyerInfo, setShowBuyerInfo] = useState(false);

  const stateProvinceLabel = (userInfo.countryCd === 'CAN') ? 'PROVINCE: ' : 'STATE: ';
  const zipPostalLabel = (userInfo.countryCd === 'CAN') ? 'POSTAL CODE:' : 'ZIP CODE:';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          tireConditionResponse,
          overallConditionResponse,
          generalConditionResponse,
          interiorConditionResponse,
          glassConditionResponse,
          mechanicalConditionResponse,
          stateOrProviceResponse,
          auctionLocationListResponse,
          vcr,
          vehloc,
          groundingInfo,
          assigmentInfoResp,
          VcrFormResponse,
          VehlocFormResponse,
          AssignmentStatusResp,
        ] = await Promise.all([
          VCRService.getTireCondition(),
          VCRService.getOverallCondition(),
          VCRService.getGeneralCondition(),
          VCRService.getInteriorCondition(),
          VCRService.getGlassCondition(),
          VCRService.getMechanicalCondition(),
          VCRService.getStateOrProvinceByStAbbr(userInfo.countryCd),
          VCRService.getAuctionLocationList(userInfo.countryCd),
          AssignmentService.getVcrDetails(teamWrkBpiNb),
          AssignmentService.getVehLocDetails(teamWrkBpiNb),
          AssignmentService.getGroundingInfo(teamWrkBpiNb),
          AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb),
          VCRService.getVcrObj(),
          VCRService.getVehLocObj(),
          AssignmentService.getRepoAssignmentStatusInfo(teamWrkBpiNb, userInfo.cntrctrNb)
        ]);

        setTireConditionOptions(tireConditionResponse.data);
        setOverallConditionOptions(overallConditionResponse.data);
        setGeneralConditionOptions(generalConditionResponse.data);
        setInteriorConditionOptions(interiorConditionResponse.data);
        setGlassConditionOptions(glassConditionResponse.data);
        setMechanicalConditionOptions(mechanicalConditionResponse.data);
        setStateOrProvince(stateOrProviceResponse);
        setAuctionLocationList(auctionLocationListResponse.data);
        setVcrFormData({
          ...VcrFormResponse.data,
          teamWrkBpiNb: teamWrkBpiNb,
          lstUpdtUserId: userInfo.userid,
        });
        setVehLocData({
          ...VehlocFormResponse.data,
          teamWrkBpiNb: teamWrkBpiNb,
          lstUpdtUserId: userInfo.userid,
        });

        if (vcr !== null && vehloc !== null) {
          setVcrFormData(() => ({
            'teamWrkBpiNb': teamWrkBpiNb,
            'rcvrDt': vcr?.data?.vcr?.rcvrDt ?? '',
            'prepByTx': vcr?.data?.vcr?.prepByTx ?? '',
            'prepDt': vcr?.data?.vcr?.prepDt ?? '',
            'vehMlgeNb': vcr?.data?.vcr?.vehMlgeNb ?? '',
            'keysInd': vcr?.data?.vcr?.keysInd ?? '',
            'accAcIn': vcr?.data?.vcr?.accAcIn ?? 'N',
            'accPwrSeatIn': vcr?.data?.vcr?.accPwrSeatIn ?? 'N',
            'accFmRadIn': vcr?.data?.vcr?.accFmRadIn ?? 'N',
            'accTiltIn': vcr?.data?.vcr?.accTiltIn ?? 'N',
            'accAtrnsIn': vcr?.data?.vcr?.accAtrnsIn ?? 'N',
            'accVnlRfIn': vcr?.data?.vcr?.accVnlRfIn ?? 'N',
            'accPwrWinIn': vcr?.data?.vcr?.accPwrWinIn ?? 'N',
            'accAftcRdoIn': vcr?.data?.vcr?.accAftcRdoIn ?? 'N',
            'accCrsCtlIn': vcr?.data?.vcr?.accCrsCtlIn ?? 'N',
            'accDieselIn': vcr?.data?.vcr?.accDieselIn ?? 'N',
            'genCndtnCd': vcr?.data?.vcr?.genCndtnCd ?? '',
            'mechCndtnCd': vcr?.data?.vcr?.mechCndtnCd ?? '',
            'glsCndtnCd': vcr?.data?.vcr?.glsCndtnCd ?? '',
            'glsBrknDs': vcr?.data?.vcr?.glsBrknDs ?? '',
            'lfTireCndtnCd': vcr?.data?.vcr?.lfTireCndtnCd ?? '',
            'rfTireCndtnCd': vcr?.data?.vcr?.rfTireCndtnCd ?? '',
            'lrTireCndtnCd': vcr?.data?.vcr?.lrTireCndtnCd ?? '',
            'rrTireCndtnCd': vcr?.data?.vcr?.rrTireCndtnCd ?? '',
            'spTireCndtnCd': vcr?.data?.vcr?.spTireCndtnCd ?? '',
            'intCndtnCd': vcr?.data?.vcr?.intCndtnCd ?? '',
            'intExplDs': vcr?.data?.vcr?.intExplDs ?? '',
            'ovrlBodyCndtnCd': vcr?.data?.vcr?.ovrlBodyCndtnCd ?? '',
            'ovrlPntCndtnCd': vcr?.data?.vcr?.ovrlPntCndtnCd ?? '',
            'ovrlBmprCndtnCd': vcr?.data?.vcr?.ovrlBmprCndtnCd ?? '',
            'cndtnBumpfIn': vcr?.data?.vcr?.cndtnBumpfIn ?? 'N',
            'cndtnBumprIn': vcr?.data?.vcr?.cndtnBumprIn ?? 'N',
            'cndtnDsfDrIn': vcr?.data?.vcr?.cndtnDsfDrIn ?? 'N',
            'cndtnDsfQpIn': vcr?.data?.vcr?.cndtnDsfQpIn ?? 'N',
            'cndtnDsrDrIn': vcr?.data?.vcr?.cndtnDsrDrIn ?? 'N',
            'cndtnDsrQpIn': vcr?.data?.vcr?.cndtnDsrQpIn ?? 'N',
            'cndtnHoodIn': vcr?.data?.vcr?.cndtnHoodIn ?? 'N',
            'cndtnPsfDrIn': vcr?.data?.vcr?.cndtnPsfDrIn ?? 'N',
            'cndtnPsfQpIn': vcr?.data?.vcr?.cndtnPsfQpIn ?? 'N',
            'cndtnPsrDrIn': vcr?.data?.vcr?.cndtnPsrDrIn ?? 'N',
            'cndtnPsrQpIn': vcr?.data?.vcr?.cndtnPsrQpIn ?? 'N',
            'cndtnRoofIn': vcr?.data?.vcr?.cndtnRoofIn ?? 'N',
            'cndtnTrnkIn': vcr?.data?.vcr?.cndtnTrnkIn ?? 'N',
            'vehRcvOthrSpcfy': vcr?.data?.vcr?.vehRcvOthrSpcfy ?? '',
            'vehRcvrdFromNm': vcr?.data?.vcr?.vehRcvrdFromNm ?? '',
            'vehRcvrdAddrTx': vcr?.data?.vcr?.vehRcvrdAddrTx ?? '',
            'vehRcvrdCityNm': vcr?.data?.vcr?.vehRcvrdCityNm ?? '',
            'vehRcvrdStAbbrCd': vcr?.data?.vcr?.vehRcvrdStAbbrCd ?? '',
            'vehRcvrdZipCd': vcr?.data?.vcr?.vehRcvrdZipCd ?? '',
            'cstRlsIn': vcr?.data?.vcr?.cstRlsIn ?? '',
            'cstRlsDs': vcr?.data?.vcr?.cstRlsDs ?? '',
            'athNtfyIn': vcr?.data?.vcr?.athNtfyIn ?? '',
            'athNtfyNm': vcr?.data?.vcr?.athNtfyNm ?? '',
            'athNtfyAddrTx': vcr?.data?.vcr?.athNtfyAddrTx ?? '',
            'athNtfyCityNm': vcr?.data?.vcr?.athNtfyCityNm ?? '',
            'athNtfyStAbbrCd': vcr?.data?.vcr?.athNtfyStAbbrCd ?? '',
            'athNtfyZipCd': vcr?.data?.vcr?.athNtfyZipCd ?? '',
            'othCmtDs': vcr?.data?.vcr?.othCmtDs ?? '',
            'dmgEstAm': vcr?.data?.vcr?.dmgEstAm ?? '',
            'msgEquipDs': vcr?.data?.vcr?.msgEquipDs ?? '',
            'vehDmgTx': vcr?.data?.vcr?.vehDmgTx ?? '',
            'lstUpdtUserId': userInfo.userid.trim(),
          }));
          setVehLocData(() => ({
            ...vehloc.data,
            'vehlocTypCd': groundingInfo?.data?.vehLocTypCd ?? '',
            'auctCd': groundingInfo?.data?.auctLocCd ?? '',
            'auctDs': groundingInfo?.data?.auctLocNm ?? '',
            'teamWrkBpiNb': teamWrkBpiNb,
            'addrLine1Tx': vehloc?.data?.addrLine1Tx ?? '',
            'addrLine2Tx': vehloc?.data?.addrLine2Tx ?? '',
            'cityNm': vehloc?.data?.cityNm ?? '',
            'stAbbrCd': vehloc?.data?.stAbbrCd ?? '',
            'zipCd': vehloc?.data?.zipCd ?? '',
            'phnCityAreaCd': vehloc?.data?.phnCityAreaCd ?? '',
            'phnNb': vehloc?.data?.phnNb ?? '',
            'lstUpdtUserId': userInfo.userid.trim(),
            'vehLocNm': vehloc?.data?.vehLocNm ?? '',
          }));
        }
        setAssignmentDetails(assigmentInfoResp.data);
        if (!_.isEmpty(assigmentInfoResp.data[0].vinSerialNb)) {
          AssignmentService.getBuyerCobuyerInfo(assigmentInfoResp.data[0].vinSerialNb)
            .then((buyerCoBuyerResponse) => {
              console.log(buyerCoBuyerResponse.data[0]);
              setBuyerCoBuyerInfo(buyerCoBuyerResponse.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(`Error Fetching Buyer Co-Buyer Info: ${err}`);
            });
        } else {
          setLoading(false);
        }
      } catch (err) {
        console.log(`Error on Fetching Vehicle Condition Report Information: ${err}`);
      }
    };

    fetchData();
  }, [props.activeTab]);


  const clearFormData = async () => {
    try {
      const [VcrFormReponse
        , VehlocFormResponse] = await Promise.all([
        VCRService.getVcrObj(),
        VCRService.getVehLocObj(),
      ]);
      setVcrFormData(VcrFormReponse.data);
      setVehLocData(VehlocFormResponse.data);
    } catch (err) {
      setError(`Error fetching form object: ${err}`);
    }
  };
  const reset = async () => {
    if (await showConfirm('All data will be cleared, proceed?')) {
      clearFormData();
    } else {
      return null;
    }
  };

  const convertValidVcrData = (VcrFromData) => {
    let validData = { ...VcrFromData };
    if (VcrFromData.vehRcvrdFromNm === 'OTHER') {
      validData = {
        ...validData,
        ['vehRcvrdFromNm']: VcrFromData.vehRcvOthrSpcfy,
      };
    }
    delete validData.vehRcvOthrSpcfy;
    if (VcrFromData.keysInd === '') {
      validData = {
        ...validData,
        ['keysInd']: ' ',
      };
    }
    if (VcrFromData.athNtfyIn === '') {
      validData = {
        ...validData,
        ['athNtfyIn']: ' ',
      };
    }
    return validData;
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(VehLocData);
    let errormsg = validatevcr(VcrFromData, VehLocData, userInfo.countryCd);
    try {
      if (errormsg !== null) {
        await showAlert(errormsg, 'warning');
      } else {
        let validVcrData = convertValidVcrData(VcrFromData);
        if (saveVcr(validVcrData)) {
          if ((saveAuctionLoc(VehLocData))) {
            if (VehLocData.vehLoc !== 'A') {
              let dbvehlocData = {
                'teamWrkBpiNb': teamWrkBpiNb,
                'vehLocNm': VehLocData.vehLocNm,
                'addrLine1Tx': VehLocData.addrLine1Tx,
                'addrLine2Tx': VehLocData.addrLine2Tx,
                'cityNm': VehLocData.cityNm,
                'stAbbrCd': VehLocData.stAbbrCd,
                'zipCd': VehLocData.zipCd,
                'phnCityAreaCd': VehLocData.phnCityAreaCd,
                'phnNb': VehLocData.phnNb,
                'lstUpdtUserId': userInfo.userid,
              };
              if (saveVehLoc(dbvehlocData)) {
                showAlert('VCR & Vehloc Information Saved Successfully!!', 'success');
                props.onContinue();
              } else {
                console.error('Failed to save vehicle location.');
              }
            } else {
              showAlert('VCR & Vehloc Information Saved Successfully!!', 'success');
              props.onContinue();
            }
          }
        } else {
          console.error('Failed to save VCR data.');
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  //insert into ZBPT102_VCR table
  async function saveVcr(vcrData) {
    try {
      console.log(vcrData);
      const response = VCRService.saveVcr(vcrData);
      let wf2Obj = {
        teamWrkBpiNb: teamWrkBpiNb,
        wfCmtCd: 'G',
        wfCmtTx: 'VCR | Mileage | ' + vcrData.vehMlgeNb,
        wfUserIdCd: userInfo.userid,
        lstUpdtUserId: userInfo.userid,

      };
      const response2 = await VCRService.InsWorkflowCommentForWF2(wf2Obj);
      console.log(response2.data);
      let vcrOtherComment = vcrData.othCmtDs.trim();
      if (vcrOtherComment.length !== 0) {
        let com1 = 'VCR | Other Comments | ' + vcrOtherComment;
        wf2Obj = {
          teamWrkBpiNb: teamWrkBpiNb,
          wfCmtCd: 'G',
          wfCmtTx: com1,
          wfUserIdCd: userInfo.userid,
          lstUpdtUserId: userInfo.userid,

        };
        const response3 = await VCRService.InsWorkflowCommentForWF2(wf2Obj);
        console.log(response3.data);

      }
      console.log('VCR Information Saved Successfully!!' + response);
      return true;
    } catch (err) {
      console.error(err);
      showAlert(err.message, 'error');
      return false;

    }
  }

  function saveVehLoc(VehLocData) {
    try {
      console.log(VehLocData);
      const response = VCRService.saveVehLoc(VehLocData);
      console.log('Vehloc Information Saved Successfully!!' + response);
      return true;
    } catch (err) {
      console.error(err);
      //setError(`Error fetching form object: ${err.message}`);
      showAlert(err.message, 'error');

    }
  }

  function saveAuctionLoc(VehLocData) {
    try {
      if (VehLocData.vehlocTypCd === 'A') {
        let auctionData = {
          auctLocCd: VehLocData.auctCd,
          auctLocNm: VehLocData.auctDs,
          propRcvrIn: 'N',
          userId: userInfo.userid,
          bpiNb: teamWrkBpiNb,
        };
        const response = VCRService.updateAuctionGrounding(auctionData);
        console.log('Vehloc Information Saved Successfully!!');
        return true;
      } else {
        let nonAuctionData = {
          vehlocTypCd: VehLocData.vehlocTypCd,
          propRcvrIn: 'N',
          userId: userInfo.userid,
          bpiNb: teamWrkBpiNb,
        };
        const response = VCRService.updateNonAuctionGrounding(nonAuctionData);
        console.log('Vehloc Information Saved Successfully!!' + response);
        return true;
      }

    } catch (err) {
      console.error(err);
      //setError(`Error fetching form object: ${err.message}`);
      showAlert(err.message, 'error');

    }
  }

  const onChangeHandler = (event) => {
    setVcrFormData(() => ({
      ...VcrFromData,
      [event.target.name]: event.target.value,
    }));
  };
  const onChangeHandlerCheckBox = (event) => {
    console.log(event.target.checked);
    const { name, checked } = event.target;

    setVcrFormData((prevData) => ({
      ...prevData,
      [name]: checked ? 'Y' : 'N',  // Set to "Y" if checked, "N" if unchecked
    }));
  };
  const onChangeHandlerVehLoc = (event) => {
    setVehLocData(() => ({
      ...VehLocData,
      [event.target.name]: event.target.value,
    }));
  };
  const onChangeHandlerAucLoc = (e) => {
    const selectedOption = AuctionLocationList.find(
      (option) => option.auctLocDs === e.target.value,
    );

    setVehLocData((prevData) => ({
      ...prevData,
      auctCd: selectedOption ? selectedOption.auctLocCd : '',
      auctDs: selectedOption ? selectedOption.auctLocDs : '',
    }));
  };
  const onChangeHandlerInt = (event) => {
    setVcrFormData(() => ({
      ...VcrFromData,
      [event.target.name]: Number(event.target.value),
    }));
  };

  if (loading) {
    return (
      <>
        <Header heading="" />
        <br />
        Loading...
      </>
    );
  }
  return (
    <>
      <div>
        {Error ? (
          <div className="error-message" style={{ color: 'red' }}>
            {Error}
          </div>
        ) : (
          <div className="data-content">
            <br />
            <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
            <div style={{ textAlign: 'center', padding: 2 + 'em' }}>
              VEHICLE CONDITION REPORT
              <br />
              Vehicle Disposal Repossession Analysis
            </div>
            {/*-----form design started----*/}
            {/*---Customer details----*/}
            <form onSubmit={handleSubmit}>
              <fieldset disabled={props.status === 'H' ? true : false}>
                <div className="f-0">
                  <div className="fds-layout-grid p-0">
                    {_.isEmpty(buyerCoBuyerInfo) ? (
                      <div>No buyer or co-buyer information available.</div>
                    ) : (
                      buyerCoBuyerInfo.filter(br => br.customerAccount.applicant.applicantCode === 'PR').map((buyerInfo, index) => {
                        const { customerAccount } = buyerInfo;
                        const { applicant, account } = customerAccount;
                        const { firstName1, firstName2, firstName3, lastName1, lastName2, lastName3 } = applicant;

                        return (
                          <div key={account.receivableAccountNumber} className="fds-layout-grid__inner p-0">
                            <div className="fds-layout-grid__cell--span-6">
                              CUSTOMER
                              NAME: {firstName1} {firstName2} {firstName3} {lastName1} {lastName2 || ''} {lastName3 || ''}
                            </div>
                            <div className="fds-layout-grid__cell--span-6">
                              ACCOUNT NO: {account.receivableAccountNumber}
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <hr />
                  {/*------Date of recovery info----*/}
                  <div style={{ textAlign: 'center' }}>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          <label>DATE OF RECOVERY: </label>
                          <input type="date" placeholder="Lorem Ipsum" className="s-1"
                                 name="rcvrDt"
                                 value={VcrFromData.rcvrDt}
                                 onChange={onChangeHandler} />
                        </div>
                        {buyerCoBuyerInfo.map((buyerCoBuyerInfo) => {
                            let customerInfo = buyerCoBuyerInfo.customerAccount;
                            return (
                              <div className=" row fds-layout-grid__cell--span-4">
                                <div className="column form-group">
                                  <label>Year</label>
                                  {customerInfo.account.vehicleYear}
                                </div>
                                <div className="column form-group">
                                  <label>MAKE</label>
                                  {customerInfo.account.vehicleMake}
                                </div>
                                <div className="column form-group">
                                  <label>MODEL</label>
                                  {customerInfo.account.vehicleModel}
                                </div>
                              </div>
                            );
                          },
                        )}
                        <div className=" fds-layout-grid__cell--span-4">
                          <div className="form-group">
                            <label>Vehicle Identification Number</label>
                            {assignmentDetails[0]?.vinSerialNb}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*------PREPARED BY info-----*/}
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">
                          <label>PREPARED BY:(NAME OF EMPLOYEE) </label>
                          {/*<Input placeholder="" className="i-0" value ={VcrFromData.prepByTx} onChange={onChangeHandler}/>*/}
                          <input type="text" placeholder="" name="prepByTx"
                                 value={VcrFromData.prepByTx}
                                 onChange={onChangeHandler} />
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          <label>DATE PREPARED: </label>
                          <input type="date" placeholder="Lorem Ipsum" className="s-1"
                                 name="prepDt"
                                 value={VcrFromData.prepDt}
                                 onChange={onChangeHandler} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*----General Condition-----*/}
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-3">
                          <label>MILEAGE: </label>
                          <input type="number" placeholder="" name="vehMlgeNb"
                                 value={VcrFromData.vehMlgeNb}
                                 onChange={onChangeHandlerInt} />
                        </div>
                        <div className="fds-layout-grid__cell--span-2">
                          <label>IGNITION KEY NO: {assignmentDetails[0]?.keyIgntnNb}</label>
                        </div>
                        <div className="fds-layout-grid__cell--span-2">
                          <label>COLOR: {assignmentDetails[0]?.vehColorTx}</label>
                        </div>
                        <div className="fds-layout-grid__cell--span-5">
                          <div className="form-group">
                            <label>GENERAL CONDITION: </label>
                            <fieldset className="fmc-check fmc-check--horizontal">
                              {GeneralConditionOptions.map(options => (
                                <label key={options.key}
                                       htmlFor={`genCndtnCd-${options.key}`}>
                                  <input
                                    type="radio"
                                    name="genCndtnCd"
                                    value={options.key}
                                    id={`genCndtnCd-${options.key}`}  // Ensuring the input has a unique ID
                                    onChange={onChangeHandlerInt}
                                    checked={VcrFromData.genCndtnCd === options.key}
                                  />
                                  <span className="r-0">{options.value}</span>
                                </label>
                              ))}
                            </fieldset>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/*----Keys-----*/}
                    <div>
                      <div className="fds-layout-grid p-0">
                        <div className="fds-layout-grid__cell--span-12">
                          {/* Flex container to align the key and radio buttons horizontally */}
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: '20px' }}>KEYS:</div>
                            <fieldset className="fmc-check fmc-check--horizontal"
                                      style={{ display: 'flex', gap: '20px' }}>
                              <label>
                                <input
                                  type="radio"
                                  name="keysInd"
                                  value="Y"
                                  checked={VcrFromData.keysInd === 'Y'}
                                  onChange={onChangeHandler}
                                />
                                <span>YES</span>
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="keysInd"
                                  value="N"
                                  checked={VcrFromData.keysInd === 'N'}
                                  onChange={onChangeHandler}
                                />
                                <span>NO</span>
                              </label>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  {/*--------Accessories------*/}
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-2">
                          <legend>ACCESSORIES:</legend>
                        </div>
                        <div className="fds-layout-grid__cell--span-10">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-3 fmc-check">
                              <label className="l-0">
                                <input type="checkbox" name="accAtrnsIn" value="Y"
                                       checked={VcrFromData.accAtrnsIn === 'Y'}
                                       onChange={onChangeHandlerCheckBox} />
                                <span className="r-1">Auto Trans</span>
                              </label>
                              <label className="l-0">
                                <input type="checkbox" name="accAcIn" value="Y"
                                       checked={VcrFromData.accAcIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">A/C</span>
                              </label>
                              <label className="l-0">
                                <input type="checkbox" name="accVnlRfIn" value="Y"
                                       checked={VcrFromData.accVnlRfIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">Vinyl Roof</span>
                              </label>

                            </div>
                            <div className="fds-layout-grid__cell--span-3 fmc-check">
                              <label className="l-0">
                                <input type="checkbox" name="accPwrSeatIn" value="Y"
                                       checked={VcrFromData.accPwrSeatIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">P. Seats</span>
                              </label>
                              <label className="l-0">
                                <input type="checkbox" name="accPwrWinIn" value="Y"
                                       checked={VcrFromData.accPwrWinIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">P. Window</span>
                              </label>
                              <label className="l-0">
                                <input type="checkbox" name="accFmRadIn" value="Y"
                                       checked={VcrFromData.accFmRadIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">FM Radio</span>
                              </label>
                            </div>
                            <div className="fds-layout-grid__cell--span-3 fmc-check">
                              <label className="l-0">
                                <input type="checkbox" name="accAftcRdoIn" value="Y"
                                       checked={VcrFromData.accAftcRdoIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">AM/FM/TAPE/CD</span>
                              </label>
                              <label className="l-0">
                                <input type="checkbox" name="accTiltIn" value="Y"
                                       checked={VcrFromData.accTiltIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">Tilt Wheel</span>
                              </label>
                              <label className="l-0">
                                <input type="checkbox" name="accCrsCtlIn" value="Y"
                                       checked={VcrFromData.accCrsCtlIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">Speed Control</span>
                              </label>
                            </div>
                            <div className="fds-layout-grid__cell--span-3 fmc-check">
                              <label className="l-0">
                                <input type="checkbox" name="accDieselIn" value="Y"
                                       checked={VcrFromData.accDieselIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">Diesel</span>
                              </label>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <hr />
                  Mechanical Glass Tire and interior info
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-2">
                          <div className="form-group">
                            <label>MECHANICAL:</label>
                            <fieldset className="fmc-check" style={{ marginTop: 1 + 'px' }}>
                              {MechanicalConditionOptions.map(options => (
                                <label className="l-0">
                                  <input type="radio" name="mechCndtnCd"
                                         value={options.key}
                                         onChange={onChangeHandlerInt}
                                         checked={VcrFromData.mechCndtnCd === options.key} />
                                  <span className="r-1">{options.value}</span>
                                </label>
                              ))}
                            </fieldset>
                          </div>
                        </div>
                        <div className="fds-layout-grid__cell--span-2">
                          <div className="form-group">
                            <label>GLASS:</label>
                            <fieldset className="fmc-check" style={{ marginTop: 1 + 'px' }}>
                              {Object.entries(GlassConditionOptions).map(([key, value], i) => (
                                <label className="l-0">
                                  <input type="radio" name="glsCndtnCd" value={key}
                                         onChange={onChangeHandlerInt}
                                         checked={VcrFromData.glsCndtnCd === parseInt(key)} />
                                  <span className="r-1">{value}</span>
                                </label>
                              ))}
                              <div className="form-group">
                                <label>INDICATE LOCATION:</label>
                                <input type="text" placeholder="" name="glsBrknDs"
                                       value={VcrFromData.glsBrknDs}
                                       onChange={onChangeHandler} />
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="fds-layout-grid__cell--span-4"
                             style={{ textAlign: 'center' }}>
                          <div className="form-group">
                            <label style={{ marginLeft: -15 + 'em' }}>TIRES:</label>
                            <div>
                              <label style={{ paddingRight: 0.70 + 'em' }}>Left
                                Front</label>
                              <select name="lfTireCndtnCd" id="vehicle-select"
                                      className="s-1"
                                      onChange={onChangeHandlerInt}
                                      value={VcrFromData.lfTireCndtnCd}>
                                <option value=""></option>
                                {TireConditionOptions.map(options => (
                                  <option key={options.key} value={options.key}>
                                    {options.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label style={{ paddingRight: 1.15 + 'em' }}>Left Rear</label>
                              <select name="lrTireCndtnCd" id="vehicle-select"
                                      className="s-1"
                                      onChange={onChangeHandlerInt}
                                      value={VcrFromData.lrTireCndtnCd}>
                                <option value=""></option>
                                {TireConditionOptions.map(options => (
                                  <option key={options.key} value={options.key}>
                                    {options.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label>Right Front</label>
                              <select name="rfTireCndtnCd" id="vehicle-select"
                                      className="s-1"
                                      onChange={onChangeHandlerInt}
                                      value={VcrFromData.rfTireCndtnCd}>
                                <option value=""></option>
                                {TireConditionOptions.map(options => (
                                  <option key={options.key} value={options.key}>
                                    {options.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label style={{ paddingRight: 0.40 + 'em' }}>Right
                                Rear</label>
                              <select name="rrTireCndtnCd" id="vehicle-select"
                                      className="s-1"
                                      onChange={onChangeHandlerInt}
                                      value={VcrFromData.rrTireCndtnCd}>
                                <option value=""></option>
                                {TireConditionOptions.map(options => (
                                  <option key={options.key} value={options.key}>
                                    {options.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label style={{ paddingRight: 3 + 'em' }}>Spare</label>
                              <select name="spTireCndtnCd" id="vehicle-select"
                                      className="s-1"
                                      onChange={onChangeHandlerInt}
                                      value={VcrFromData.spTireCndtnCd}>
                                <option value=""></option>
                                {TireConditionOptions.map(options => (
                                  <option key={options.key} value={options.key}>
                                    {options.value}
                                  </option>
                                ))}
                              </select>
                            </div>

                          </div>
                        </div>
                        <div className="fds-layout-grid__cell--span-4">
                          <div className="form-group">
                            <label>INTERIOR:</label>
                            <fieldset className="fmc-check" style={{ marginTop: 1 + 'px' }}>
                              <option value=""></option>
                              {InteriorConditionOptions.map(options => (
                                <label className="l-0">
                                  <input type="radio" name="intCndtnCd"
                                         value={options.key}
                                         onChange={onChangeHandlerInt}
                                         checked={VcrFromData.intCndtnCd === options.key} />
                                  <span className="r-1">{options.value}</span>
                                </label>
                              ))}
                              <div>
                                <label>EXPLANATION:</label>
                                <input type="text" placeholder="" name="intExplDs"
                                       value={VcrFromData.intExplDs}
                                       onChange={onChangeHandler} />
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*---Body condition details---*/}
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          <div className="form-group">
                            <label>BODY:</label>
                            <div>
                              <label style={{ paddingRight: 2.6 + 'em' }}>Body</label>
                              <select name="ovrlBodyCndtnCd" id="vehicle-select"
                                      className="s-1"
                                      onChange={onChangeHandlerInt}
                                      value={VcrFromData.ovrlBodyCndtnCd}>
                                <option value=""></option>
                                {OverallConditionOptions.map(options => (
                                  <option key={options.key} value={options.key}>
                                    {options.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label style={{ paddingRight: 2.5 + 'em' }}>Paint</label>
                              <select name="ovrlPntCndtnCd" id="vehicle-select"
                                      className="s-1"
                                      onChange={onChangeHandlerInt}
                                      value={VcrFromData.ovrlPntCndtnCd}>
                                <option value=""></option>
                                {OverallConditionOptions.map(options => (
                                  <option key={options.key} value={options.key}>
                                    {options.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label style={{ paddingRight: 0.5 + 'em' }}>Bumpers</label>
                              <select name="ovrlBmprCndtnCd" id="vehicle-select"
                                      className="s-1"
                                      onChange={onChangeHandlerInt}
                                      value={VcrFromData.ovrlBmprCndtnCd}>
                                <option value=""></option>
                                {OverallConditionOptions.map(options => (
                                  <option key={options.key} value={options.key}>
                                    {options.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="fds-layout-grid__cell--span-8">
                          <div className="form-group">
                            <label>Dent/Rust Location:</label>
                            <div className="fds-layout-grid__inner p-0 ">
                              <div className="fds-layout-grid__cell--span-4 fmc-check">
                                <label>Driver Side:</label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnDsfQpIn" value="Y"
                                         checked={VcrFromData.cndtnDsfQpIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Front Quarter Panel</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnDsfDrIn" value="Y"
                                         checked={VcrFromData.cndtnDsfDrIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Front Door</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnDsrDrIn" value="Y"
                                         checked={VcrFromData.cndtnDsrDrIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Rear Door</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnDsrQpIn" value="Y"
                                         checked={VcrFromData.cndtnDsrQpIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Rear Quarter Panel</span>
                                </label>
                              </div>
                              <div className="fds-layout-grid__cell--span-4 fmc-check">
                                <label>Passenger Side:</label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnPsfQpIn" value="Y"
                                         checked={VcrFromData.cndtnPsfQpIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Front Quarter Panel</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnPsfDrIn" value="Y"
                                         checked={VcrFromData.cndtnPsfDrIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Front Door</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnPsrDrIn" value="Y"
                                         checked={VcrFromData.cndtnPsrDrIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Rear Door</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnPsrQpIn" value="Y"
                                         checked={VcrFromData.cndtnPsrQpIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Rear Quarter Panel</span>
                                </label>
                              </div>
                              <div className="fds-layout-grid__cell--span-2 fmc-check">
                                <label>Top:</label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnHoodIn" value="Y"
                                         checked={VcrFromData.cndtnHoodIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Hood</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnRoofIn" value="Y"
                                         checked={VcrFromData.cndtnRoofIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Roof</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnTrnkIn" value="Y"
                                         checked={VcrFromData.cndtnTrnkIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Trunk</span>
                                </label>
                              </div>
                              <div className="fds-layout-grid__cell--span-1 fmc-check">
                                <label>Bumper:</label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnBumpfIn" value="Y"
                                         checked={VcrFromData.cndtnBumpfIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Front</span>
                                </label>
                                <label className="l-0">
                                  <input type="checkbox" name="cndtnBumprIn" value="Y"
                                         checked={VcrFromData.cndtnBumprIn === 'Y'}
                                         onChange={onChangeHandlerCheckBox} />
                                  <span className="r-1">Rear</span>
                                </label>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*---Remarks-- */}
                  <div className="p-0">
                    <div className="fds-layout-grid p-0">
                      <div className="form-group">
                        <label>REMARKS/MISSING EQUIPMENT :</label>
                        <input type="text" placeholder="" className="i-1" name="msgEquipDs"
                               value={VcrFromData.msgEquipDs}
                               onChange={onChangeHandler} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="p-0">
                    <div className="fds-layout-grid p-0">
                      <div>
                        <label>ESTIMATED INSURANCE DAMAGE AMOUNT :</label>
                        <input type="text" placeholder="" style={{ 'width': 10 + 'em' }}
                               name="dmgEstAm"
                               value={VcrFromData.dmgEstAm}
                               onChange={onChangeHandlerInt} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*VEHICLE RECOVERED FROM*/}
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">
                          <div className="form-group">
                            <label>Vehicle Recovered From</label>
                            <div className="fmc-check--horizontal">
                              <label className="l-0">
                                <input type="radio" name="vehRcvrdFromNm"
                                       value="CUSTOMER"
                                       onChange={onChangeHandler}
                                       checked={VcrFromData.vehRcvrdFromNm === 'CUSTOMER'} />
                                <span className="r-1">CUSTOMER</span>
                              </label>
                              <label className="l-0">
                                <input type="radio" name="vehRcvrdFromNm" value="OTHER"
                                       onChange={onChangeHandler}
                                       checked={VcrFromData.vehRcvrdFromNm === 'OTHER'} />
                                <span className="r-1">OTHER (SPECIFY)</span>
                              </label>
                              <label className="l-0">
                                <input type="text" id="vehRcvOthrSpcfy"
                                       style={{ 'width': 10 + 'em' }}
                                       name="vehRcvOthrSpcfy"
                                       value={VcrFromData.vehRcvOthrSpcfy === 'CUSTOMER' ? '' : VcrFromData.vehRcvOthrSpcfy}
                                       disabled={VcrFromData.vehRcvrdFromNm === 'CUSTOMER' && VcrFromData.vehRcvrdFromNm !== ''}
                                       onChange={onChangeHandler} />
                              </label>

                            </div>
                          </div>
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          <div className="form-group">
                            <label>Recovered From</label>
                            <div>
                              <label className="width100">ADDRESS:</label>
                              <input type="text" className="s-1" name="vehRcvrdAddrTx"
                                     value={VcrFromData.vehRcvrdAddrTx}
                                     onChange={onChangeHandler} />
                            </div>
                            <div>
                              <label className="width100">CITY:</label>
                              <input type="text" className="s-1" name="vehRcvrdCityNm"
                                     value={VcrFromData.vehRcvrdCityNm}
                                     onChange={onChangeHandler} />
                            </div>
                            <div>
                              <label className="l-0">
                                                            <span className="r-1 width100"
                                                            >{stateProvinceLabel}</span>
                                <select name="vehRcvrdStAbbrCd" className="s-2"
                                        onChange={onChangeHandler}
                                        value={VcrFromData.vehRcvrdStAbbrCd}>
                                  <option value=""></option>
                                  {StateOrProvince?.map(options => (
                                    <option key={options.stAbbrCd}
                                            value={options.stAbbrCd}>
                                      {options.stAbbrCd}
                                    </option>
                                  ))}
                                </select>
                              </label>
                              <label className="l-0">
                                <span className="r-1">{zipPostalLabel} </span>
                                <input type="text" className="s-3" name="vehRcvrdZipCd"
                                       value={VcrFromData.vehRcvrdZipCd}
                                       onChange={onChangeHandler} />
                              </label>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*Customer sign details*/}
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-8">
                          <div className="fmc-check">
                            <label>WAS RELEASED SIGNED BY CUSTOMER? </label>
                            <label className="l-0">
                              <input type="radio" name="cstRlsIn" value="Y"
                                     onChange={onChangeHandler}
                                     checked={VcrFromData.cstRlsIn === 'Y'} />
                              <span className="r-1">YES</span>
                            </label>
                            <label className="l-0">
                              <input type="radio" name="cstRlsIn" value="N"
                                     onChange={onChangeHandler}
                                     checked={VcrFromData.cstRlsIn === 'N'} />
                              <span className="r-1">NO.    IF NO, EXPLAIN  </span>
                              <input type="text" className="s-0" name="cstRlsDs"
                                     value={VcrFromData.cstRlsDs}
                                     disabled={VcrFromData.cstRlsIn === 'Y'}
                                     onChange={onChangeHandler} />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          <div className="form-group">
                            <label>IF NO, WERE PROPER AUTHORITIES NOTIFIED?</label>
                            <div className="fmc-check--horizontal">
                              <label className="l-0">
                                <input type="radio" name="athNtfyIn" value="N"
                                       onChange={onChangeHandler}
                                       checked={VcrFromData.athNtfyIn === 'N'} />
                                <span className="r-1">NO. IF NO, EXPLAIN BELOW</span>
                              </label>
                              <label className="l-0">
                                <input type="radio" name="athNtfyIn" value="Y"
                                       checked={VcrFromData.athNtfyIn === 'Y'}
                                       onChange={onChangeHandler} />
                                <span className="r-1">YES</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          <div className="form-group">
                            <label>AUTHORITIES NOTIFIED:</label>
                            <div>
                              <label className="width100">NAME:</label>
                              <input type="text" className="s-1" name="athNtfyNm"
                                     value={VcrFromData.athNtfyNm}
                                     disabled={VcrFromData.athNtfyIn === 'N'}
                                     onChange={onChangeHandler} />
                            </div>
                            <div>
                              <label className="width100">ADDRESS:</label>
                              <input type="text" className="s-1" name="athNtfyAddrTx"
                                     value={VcrFromData.athNtfyAddrTx}
                                     disabled={VcrFromData.athNtfyIn === 'N'}
                                     onChange={onChangeHandler} />
                            </div>
                            <div>
                              <label className="width100">CITY:</label>
                              <input type="text" className="s-1" name="athNtfyCityNm"
                                     value={VcrFromData.athNtfyCityNm}
                                     disabled={VcrFromData.athNtfyIn === 'N'}
                                     onChange={onChangeHandler} />
                            </div>
                            <div>
                              <label className="l-0">
                                <span className="r-1 width100">{stateProvinceLabel}</span>
                                <select name="athNtfyStAbbrCd" className="s-2"
                                        onChange={onChangeHandler}
                                        disabled={VcrFromData.athNtfyIn === 'N'}
                                        value={VcrFromData.athNtfyStAbbrCd}>
                                  <option value=""></option>
                                  {StateOrProvince?.map(options => (
                                    <option key={options.stAbbrCd}
                                            value={options.stAbbrCd}>
                                      {options.stAbbrCd}
                                    </option>
                                  ))}
                                </select>
                              </label>
                              <label className="l-0">
                                <span className="r-1">{zipPostalLabel}</span>
                                <input type="text" className="s-3" name="athNtfyZipCd"
                                       value={VcrFromData.athNtfyZipCd}
                                       disabled={VcrFromData.athNtfyIn === 'N'}
                                       onChange={onChangeHandler} />
                              </label>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*OTHER COMMENTS*/}
                  <div className="p-0">
                    <div className="fds-layout-grid p-0">
                      <div className="form-group">
                        <label>OTHER COMMENTS :</label>
                        <input type="text" placeholder=""
                               className="i-1" name="othCmtDs" style={{ 'height': 100 + 'px' }}
                               value={VcrFromData.othCmtDs}
                               onChange={onChangeHandler} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/*   vehicle located at */}
                  <div>
                    <div className="fds-layout-grid p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-12">
                          <label>VEHICLE LOCATED AT:</label>
                          <div className="fmc-check">
                          </div>

                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          <div className="form-group">
                            <div className="fmc-check">
                              <label className="l-0">
                                <input type="radio" name="vehlocTypCd" value="A"
                                       checked={VehLocData.vehlocTypCd === 'A'}
                                       onChange={onChangeHandlerVehLoc} />
                                <span className="r-1">AUCTION</span>
                                <select name="auctDs" id="vehicle-select"
                                        className="s-4"
                                        onChange={onChangeHandlerAucLoc}
                                        disabled={VehLocData.vehlocTypCd !== 'A' & VehLocData.vehlocTypCd !== ''}
                                        value={VehLocData.auctDs}>
                                  <option value=""></option>
                                  {AuctionLocationList.map(options => (
                                    <option key={options.auctLocCd} value={options.auctLocDs}>
                                      {options.auctLocDs}
                                    </option>
                                  ))}
                                </select>
                              </label>

                              <label className="l-0">
                                <input type="radio" name="vehlocTypCd" value="C"
                                       checked={VehLocData.vehlocTypCd === 'C'}
                                       onChange={onChangeHandlerVehLoc} />
                                <span className="r-1">CONTRACTOR</span>
                              </label>
                              <label className="l-0">
                                <input type="radio" name="vehlocTypCd" value="D"
                                       checked={VehLocData.vehlocTypCd === 'D'}
                                       onChange={onChangeHandlerVehLoc} />
                                <span className="r-1">DEALER</span>
                              </label>
                              <label className="l-0">
                                <input type="radio" name="vehlocTypCd" value="O"
                                       checked={VehLocData.vehlocTypCd === 'O'}
                                       onChange={onChangeHandlerVehLoc} />
                                <span className="r-1">OTHER</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          <div className="form-group">
                            <div>
                              <label style={{ paddingRight: 6 + 'em' }}></label>
                              <input type="hidden" className="s-1" />
                            </div>
                            <div>
                              <label style={{ paddingRight: 6 + 'em' }}>NAME:</label>
                              <input type="text" className="s-1" name="vehLocNm"
                                     value={VehLocData.vehLocNm}
                                     disabled={VehLocData.vehlocTypCd === 'A' & VehLocData.vehlocTypCd !== ''}
                                     onChange={onChangeHandlerVehLoc} />
                            </div>
                            <div>
                              <label style={{ paddingRight: 0.5 + 'em' }}>ADDRESS
                                LINE1:</label>
                              <input type="text" className="s-1" name="addrLine1Tx"
                                     value={VehLocData.addrLine1Tx}
                                     disabled={VehLocData.vehlocTypCd === 'A' & VehLocData.vehlocTypCd !== ''}
                                     onChange={onChangeHandlerVehLoc} />
                            </div>
                            <div>
                              <label style={{ paddingRight: 0.4 + 'em' }}>ADDRESS
                                LINE2:</label>
                              <input type="text" className="s-1" name="addrLine2Tx"
                                     value={VehLocData.addrLine2Tx}
                                     disabled={VehLocData.vehlocTypCd === 'A' & VehLocData.vehlocTypCd !== ''}
                                     onChange={onChangeHandlerVehLoc} />
                            </div>
                            <div>
                              <label style={{ paddingRight: 7 + 'em' }}>CITY:</label>
                              <input type="text" className="s-1" name="cityNm"
                                     value={VehLocData.cityNm}
                                     disabled={VehLocData.vehlocTypCd === 'A' & VehLocData.vehlocTypCd !== ''}
                                     onChange={onChangeHandlerVehLoc} />
                            </div>
                            <div>
                              <label className="l-0">
                                                            <span className="r-1"
                                                                  style={{
                                                                    display: 'inline-block',
                                                                    width: '131px',
                                                                  }}>{stateProvinceLabel}</span>
                                <select name="stAbbrCd" className="s-2"
                                        value={VehLocData.stAbbrCd}
                                        onChange={onChangeHandlerVehLoc}
                                        disabled={VehLocData.vehlocTypCd === 'A' & VehLocData.vehlocTypCd !== ''}>
                                  <option value=""></option>
                                  {StateOrProvince?.map(options => (
                                    <option key={options.stAbbrCd}
                                            value={options.stAbbrCd}>
                                      {options.stAbbrCd}
                                    </option>
                                  ))}
                                </select>
                              </label>
                              <label className="l-0">
                                <span className="r-1">{zipPostalLabel}</span>
                                <input type="text" className="s-3" name="zipCd"
                                       value={VehLocData.zipCd}
                                       disabled={VehLocData.vehlocTypCd === 'A' & VehLocData.vehlocTypCd !== ''}
                                       onChange={onChangeHandlerVehLoc} />
                              </label>
                            </div>
                            <div>
                              <label className="l-0">
                                                            <span className="r-1"
                                                                  style={{ paddingRight: 5 + 'em' }}>PHONE:</span>
                                (<input type="text" className="s-2" name="phnCityAreaCd"
                                        value={VehLocData.phnCityAreaCd}
                                        disabled={VehLocData.vehlocTypCd === 'A' & VehLocData.vehlocTypCd !== ''}
                                        onChange={onChangeHandlerVehLoc} />)
                              </label>
                              <label className="l-0">
                                <input type="text" className="s-1" name="phnNb"
                                       value={VehLocData.phnNb}
                                       disabled={VehLocData.vehlocTypCd === 'A' & VehLocData.vehlocTypCd !== ''}
                                       onChange={onChangeHandlerVehLoc} />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div style={{ textAlign: 'center', paddingTop: 3 + 'em', fontSize: '0.75em' }}>
                <span className="dot"></span>
                Prior to recording the mileage reflected on the vehicle's odometer. Consider the
                age,
                appearance and
                condition of the vehicle and look for the indicators of mileage discrepancies such
                as
                oil change
                stickers, repair work receipts, waranty book entries and odometer replacement
                stickers.
                Note any
                mileage
                discrepancies or inconsistencies in the Comments section.
              </div>
              <div style={{
                textAlign: 'center',
                paddingTop: 1 + 'em',
                fontSize: '0.75em',
                margin: 2 + 'em',
              }}>
                <span className="dot"></span>
                California Repossessions: In accordance with Section 7505. 115of Business and
                Professions code,
                this condition report is a general assessment of the collateral and does not
                including
                all damage or
                missing parts.
              </div>
              <section style={{ padding: 2 + 'em' }}>
                <div className="fds-align--center">
                  <span>FCNA 16779</span>
                  <button type="submit" className="fmc-button"
                          style={{ marginLeft: 0.5 + 'em' }}>Continue
                  </button>
                  <button type="button" className="fmc-button" style={{ marginLeft: 2 + 'em' }}
                          onClick={reset}>Reset
                  </button>
                </div>
              </section>
            </form>

          </div>
        )}
      </div>

    </>
  );
}

export default VehicleConditionReport;