import './Summary.css';
import React, {useEffect, useState} from "react";
import {getPPropertyInfoFromSession} from "../../Services/session.mgmt";
import {AssignmentService} from "../../Services/AssignmentService";
import {getPpDetails} from "../../api/assignment.api";
function PersonalProprtySum({ppDetails , ppItems}) {

  // Initialize loading state using useState
  const [loading, setLoading] = useState(true);
  const [ppInfo, setPpInfo] = useState({
    ppIndicator: "",
    ppReturned: "",
    item01: "",
    item02: "",
    item03: "",
    item04: "",
    item05: "",
    item06: "",
    item07: "",
    item08: "",
    item09: "",
    item10: "",
    item11: "",
    item12: "",
    item13: "",
    item14: "",
    item15: "",
    wherePropertyStored: "",
    storingContractorName: "",
    customerNameDate: ""
  });

  useEffect(() => {
    try {
      // Construct ppInfo from props
      const newPpInfo = {
        ppIndicator: ppDetails?.result?.personalPropertyLocStat?.prsnPrptyStatCd ? "Y" : "N",
        ppReturned: ppDetails?.result?.personalPropertyLocStat?.prsnPrptyStatCd ?? "",
        item01: ppItems[0]?.itmNm ?? "",
        item02: ppItems[1]?.itmNm ?? "",
        item03: ppItems[2]?.itmNm ?? "",
        item04: ppItems[3]?.itmNm ?? "",
        item05: ppItems[4]?.itmNm ?? "",
        item06: ppItems[5]?.itmNm ?? "",
        item07: ppItems[6]?.itmNm ?? "",
        item08: ppItems[7]?.itmNm ?? "",
        item09: ppItems[8]?.itmNm ?? "",
        item10: ppItems[9]?.itmNm ?? "",
        item11: ppItems[10]?.itmNm ?? "",
        item12: ppItems[11]?.itmNm ?? "",
        item13: ppItems[12]?.itmNm ?? "",
        item14: ppItems[13]?.itmNm ?? "",
        item15: ppItems[14]?.itmNm ?? "",
        wherePropertyStored: ppDetails?.result?.personalPropertyLocStat?.prsnPrptyLocTx ?? "",
        storingContractorName: ppDetails?.result?.personalPropertyLocStat?.prsnPrptyLocBusNm ?? "",
        customerNameDate: `${ppDetails?.result?.personalPropertyLocStat?.lstUpdtUserId ?? ""}/${ppDetails?.result?.personalPropertyLocStat?.lstUpdtTs?.substring(0, 10) ?? ""}`,
      };

      // Set the new ppInfo into state
      setPpInfo(newPpInfo);
    } catch (err) {
      console.log(`Error on Fetching personal property details: ${err}`);
    } finally {
      setLoading(false); // Set loading to false when data is ready
    }
  }, [ppDetails, ppItems]); // Trigger effect when ppDetails or ppItems changes


  if (loading) {
    // Data is not available yet
    return <div>Loading...</div>; // Or handle as needed
  }
  return (

    <div className='details_cntr'>

      <div id='invoiceheader' className='center' >
        PERSONAL PROPERTY
      </div>
      <div id="persnprptbl_cntr">
        <section
          className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
        >
          <section
            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >
            <div >
              Personal property recoverd in  vehicle when recovered

            </div>
            <div><fieldset class="fmc-check fmc-check--horizontal">

              <label>
                <input type="radio" name="radio-button-items" value="item-1" checked={ppInfo.ppIndicator === "Y"} />
                <span>Yes</span>
              </label>

              <label>
                <input type="radio" name="radio-button-items" value="item-2" checked={ppInfo.ppIndicator === "N"} />
                <span>No</span>
              </label>

            </fieldset>
            </div>
            <div>

              If yes  itemize  the following form and  when returned to customer have him sign in space provided.
            </div>
          </section>

          <section

            className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
          >

            <div><fieldset class="fmc-check fmc-check--horizontal">

              <label>
                <input type="radio" name="ppReturned"
                       value="R" checked={ppInfo.ppReturned === "R"} />
                <span>Personal property returned</span>
              </label>

              <label>
                <input type="radio"name="ppReturned"
                       value="D" checked={ppInfo.ppReturned === "D"} />
                <span>Personal Property disposed</span>
              </label>

            </fieldset>
            </div>
          </section>

        <div id="personpro_itm_cntr">
          <table class="fmc-table" >
            <thead>
              <tr>

              </tr>
            </thead>
            <tbody>

              <tr>
                <td>1  {ppInfo.item01}</td>
                <td>2  {ppInfo.item02}</td>
                <td>3  {ppInfo.item03}</td>
              </tr>
              <tr>
                <td>4  {ppInfo.item04 }</td>
                <td>5  {ppInfo.item05 }</td>
                <td>6  {ppInfo.item06 }</td>
              </tr>
              <tr>
                <td>7  {ppInfo.item07 }</td>
                <td>8  {ppInfo.item08 }</td>
                <td>9  {ppInfo.item09 }</td>
              </tr>
              <tr>
                <td>10  {ppInfo.item10 }</td>
                <td>11  {ppInfo.item11 }</td>
                <td>12  {ppInfo.item12 }</td>
              </tr>
              <tr>
                <td>13  {ppInfo.item13 }</td>
                <td>14  {ppInfo.item14 }</td>
                <td>15  {ppInfo.item15 }</td>
              </tr>
              <tr>
                <td>
                  <tr>Where Property stored</tr>
                  <tr>{ppInfo.wherePropertyStored}</tr>
                </td>
                <td>
                  <tr>Naming of Storing contractor</tr>
                  <tr>{ppInfo.storingContractorName}</tr>

                </td>
                <td>I acknowledge return of listed items
                  <br/>
                  <tr>{ppInfo.customerNameDate}</tr><br/>
                  <tr> -------------------------</tr>
                  Customer's Name and date
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        </section>
      </div>
        <div> PERSONAL  PROPERTY & TRUST RECEIPT </div>
    </div>
  );

}

export default PersonalProprtySum;