import {ENDPOINTS} from "../constants/endpoints";
import axios from "axios";

const serviceAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
    withCredentials: true
});
export function getAccountInfo(teamworkBpi,cntrctrNb){

    return serviceAxiosInstance.get(ENDPOINTS['GET_ACCOUNT_INFO']+teamworkBpi+"/"+cntrctrNb);
}

export function getRepoCommonInfo(teamworkBpi,cntrctrNb){

    return serviceAxiosInstance.get(ENDPOINTS['GET_REPOCOMMON_INFO']+teamworkBpi+"/"+cntrctrNb);
}

export  function  getCntrctrInfoForComments(userId){

    return serviceAxiosInstance.get(ENDPOINTS['GET_CONTRACTOR_INFO_FOR_COMMENT']+userId);
}

export function createPinnacleContact(pinncalelogobj,userid, password){

    return axios.post(process.env.REACT_APP_SERVICE_BASE_URL_ACT+ENDPOINTS['POST_PINNCAL_LOG'], pinncalelogobj, {
        auth: {
            username: userid,
            password: password
        }
    });

}