// src/components/AuthLoginCallback.js
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { getUserInfoFromSession, setUserInfoToSession } from '../Services/session.mgmt';
import axios from "axios";
import authService from "../Services/AuthService";
import Unauthorized from "../Pages/Unauthorized/Unauthorized";
import { profileService } from '../Services/ProfileService';
import { isAfter, differenceInDays, parse } from 'date-fns';
import ExpirationNotification from './ExpirationNotification';
const DATE_FORMAT = 'MM/dd/yyyy';
const AuthLoginCallback = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [unAuthorized, setUnAuthorized] = useState(false);

    function divertUserPath(userInfo) {
        if (userInfo.isAdmin) {
            navigate('/userVerification');
        } else if (userInfo.isExternal && userInfo.hasContractProfile) {
            navigate('/misc');
        } else {
            setUnAuthorized(true);
        }
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_GATEWAY_URL}/auth/userInfo`, {
            withCredentials: true
        })
            .then(response => {
                const userInfo = {
                    userid: response.data.accessToken.userid,
                    countryCd: response.data.accessToken.countryCd,
                    sitecode: response.data.accessToken.sitecode,
                };
                Promise.all([
                    authService.isExternalUser(userInfo.sitecode),
                    authService.isAdminUser(userInfo.userid),
                    authService.getUserDetail(userInfo.userid)
                ]).then(([externalUserResp, adminUserResp, userDetailResp]) => {
                    debugger
                    userInfo['isExternal'] = !!externalUserResp.data;
                    userInfo['isAdmin'] = !!adminUserResp.data;
                    userInfo['hasContractProfile'] = userDetailResp.data.length > 0;
                    userInfo['cntrctrNb'] = userDetailResp?.data[0]?.cntrctrNb;
                    setUserInfo(userInfo);
                    if(userDetailResp?.data[0]?.cntrctrNb){
                        let currDate = new Date(), expireDate = new Date();
                        profileService.getContractorPersonal(userDetailResp.data[0].cntrctrNb).then((contractorDetail) => {
                            if(contractorDetail.data.result.cntrctrData2ApiList[0].expirDt){
                                expireDate = parse(contractorDetail.data.result.cntrctrData2ApiList[0].expirDt, DATE_FORMAT, new Date());
                                userInfo['buzLcnsExpireDate'] = contractorDetail.data.result.cntrctrData2ApiList[0].busLcnsExpirDt;
                                userInfo['insuranceExpireDate'] = contractorDetail.data.result.cntrctrData2ApiList[0].insInfoExpirDt;
                                // userInfo['insuranceExpireDate'] = '12/25/2024';
                                //verify expired profile
                                if(isAfter(currDate, expireDate)) {
                                    //block user login force logout
                                    setUserInfoToSession(null);
                                    navigate('/');
                                }else{
                                    setUserInfoToSession(userInfo);
                                    divertUserPath(userInfo);
                                }
                            }
                        });
                    }else {
                        setUserInfoToSession(userInfo);
                        divertUserPath(userInfo);
                        setLoadingUserInfo(false);
                    }
                }).catch(reason => {
                    console.log("AuthLoginCallback.retrieveUserInfo Failed: " + reason.toString());
                }).finally(() => {
                    console.log("AuthLoginCallback.retrieveUserInfo Completed with : " + JSON.stringify(userInfo));
                });
            });
    }, []);

    if(loadingUserInfo) return <>loading...</>;
    if(unAuthorized) return <Unauthorized/>;
    return (
        <div>
            <h2>Get User Token from Server...</h2>
        </div>
    );
};

export default AuthLoginCallback;
