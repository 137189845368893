import Header from './Header/Header';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parse } from 'date-fns';
import { DATE_FORMAT } from '../constants/RepoReactConstants';


const ExpirationNotification = ({ uInfo, clickCallback }) => {
  const [userInfo, setUserInfo] = useState(uInfo);
  const navigate = useNavigate();

  const handleAcknoledged = () => {
    clickCallback();
    // if (userInfo.isAdmin) {
    //   navigate('/userVerification');
    // } else if (userInfo.isExternal && userInfo.hasContractProfile) {
    //   navigate('/misc');
    // } else {
    //   navigate('/');
    // }
  };

  return (
    <div>
      <br />
      <div className="center"><h2>Expiration Information</h2></div>
      <div className="details_cntr">
        <Header heading="" />
        <br />
        <div className="details_cntr">
          <h3>Your insurance is due to expire {parse(userInfo.insuranceExpireDate, DATE_FORMAT, new Date()).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} </h3>
          <p>
            To avoid an interruption in service and possible cancellation of assignments, please have your insurance
            company e-mail the new certificate to: <a href="mailto:webrepo@ford.com">webrepo@ford.com</a>.
          </p>
        </div>
        <br />
        <br />
        <div className="details_cntr">
          <h3>Your repossession license or bond is due to expire {parse(userInfo.buzLcnsExpireDate, DATE_FORMAT, new Date()).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} </h3>
          <p>
            To avoid an interruption in service and possible cancellation of assignments, please e-mail the new state
            repossession license to:<a href="mailto:webrepo@ford.com">webrepo@ford.com</a>.
          </p>
        </div>
        <div className="center" style={{ padding: '10px' }}>
          <button type="submit" className="fmc-button" onClick={handleAcknoledged}>Acknowledge</button>
        </div>
        <div className="fmc-bottom-banner__content center copy-rightbanner">
          Copyright &copy; 2024 Ford Motor Credit Company. All Rights Reserved
        </div>
      </div>
    </div>
  );
};
export default ExpirationNotification;