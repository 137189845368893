import {ENDPOINTS} from "../constants/endpoints";
import axios from "axios";
const serviceAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
    withCredentials: true
});
export function getContractorProfile(userId){
    return serviceAxiosInstance.get(ENDPOINTS['GET_CONTRACTOR'] + '/' + userId)
}
export function getContractorNumber(userId){
    return serviceAxiosInstance.get(ENDPOINTS['GET_REPO_USER_DETAIL'] + '?userId=' + userId);
}
export function getAssignmentInfo(teamWrkBpiNb , cntrctrNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_ASSIGNMENT_INFO'] + '/' + teamWrkBpiNb + '/' + cntrctrNb);
}
export function getRepoAssignmentStatusInfo(teamWrkBpiNb , cntrctrNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_REPO_ASSIGNMENT_STATUS'] + teamWrkBpiNb + '/' + cntrctrNb);
}
export function getRepoAssignmentStatusInfoForMisc(cntrctrNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_REPO_ASSIGNMENT_STATUS_INFO_MISC'] + cntrctrNb );
}
export function getPersonalPropertyFUP(cntrctrNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_PERSONAL_PROPERTY_FUP'] + '/' + cntrctrNb);
}
export function getSummary(cntrctrNb){
    return serviceAxiosInstance.get(ENDPOINTS['GET_SUMMARY'] + '/' + cntrctrNb);
}
export function getBuyerCobuyerInfo(vinNb){
    try{
        return axios.get(process.env.REACT_APP_SERVICE_BASE_URL_EXT + ENDPOINTS['GET_BUYERCOBUYER_INFO'] + vinNb);
    }catch (error){
        console.error('Error fetching data:', error);
        return Promise.resolve([]);
    }
}
export function getVechileDsiposalInfo(){
    return axios.get(process.env.REACT_APP_SERVICE_BASE_URL_FCNA+ "api/v1/vehicleDisposal/7201/2A/347910000");
}
export function getVcrDetails(bpiNo){
    return serviceAxiosInstance.get(ENDPOINTS['GET_VCR'] + '/' + bpiNo);
}
export function getInvoiceDetails(bpiNo){
    return serviceAxiosInstance.get(ENDPOINTS['GET_INVOICE'] + '/' + bpiNo);
}
export function getPpDetails(bpiNo){
    return serviceAxiosInstance.get(ENDPOINTS['GET_PROPERTY'] + '/' + bpiNo);
}
export function getVehLocDetails(bpiNo){
    return serviceAxiosInstance.get(ENDPOINTS['GET_VEHLOC'] + '/' + bpiNo);
}
export function getGroundingInfo(bpiNo){
    return serviceAxiosInstance.get(ENDPOINTS['GET_GROUNDING_INFO'] + '/' + bpiNo);
}
export function getDbVehicleLoc(bpiNo){
    return serviceAxiosInstance.get(ENDPOINTS['GET_VEHLOC_DATA'] + '/' + bpiNo);
}
export function getDbPropertyLocStat(bpiNo){
    return serviceAxiosInstance.get(ENDPOINTS['GET_PP_LOCSTAT'] + '/' + bpiNo);
}
export function getInvoiceTypes(prodCd){
    return serviceAxiosInstance.get(ENDPOINTS['API_ENDPOINT_GET_INVOICE_CODE_LIST']  + prodCd);
}
